import gql from 'graphql-tag';
import axios, { AxiosResponse } from 'axios';
import CONSTANTS from '../constants';
import { logError, apollo } from '../../../index';
import { ApolloQueryResult } from '@apollo/client/core';
import {
  Error,
  GoogleDataParams,
  GetGoogleAnaltyicsStatusResponse,
  GoogleAnalyticsAccount,
  GetGoogleAnalyticsAvailablePropertiesResponse,
  GetGoogleAnalyticsAvailableProfilesResponse,
  GoogleAnaltyicsAvailableAccount,
  GoogleAnaltyicsAvailableProfile,
  AddGaConnectorResponse,
  DeleteGaConnectorResponse,
  GaAvailableAccountResponse,
  GaAvailablePropertyResponse,
  GaAvailableViewResponse,
  GaConnectorResponse,
} from './../types/google';
import requests from './../requests';

const getGaAvailableAccount = async ({ commit }, params: { id: string }): Promise<GaAvailableAccountResponse> => {
  if (!params.id) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ACCOUNT_ERROR, 'Missing advertiser ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ACCOUNT_ERROR, null);
    commit(CONSTANTS.SET_LOADING, true);
    const response = await apollo().query({
      query: gql`
        query GetGaAvailableAccount($params: GaAvailableAccountParams) {
          getGaAvailableAccount(params: $params) {
            data {
              id
              name
            }
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    commit(CONSTANTS.SET_GOOGLE_ANALYTIC_ACCOUNT, response.data.getGaAvailableAccount.data);
    return response.data.getGaAvailableAccount.data;
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ACCOUNT_ERROR, error.message);
    logError('getGaAvailableAccount', error);
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const getGaAvailablePropertyView = async (
  { commit },
  params: { id: string; accountId: string; propertyId: string },
): Promise<GaAvailableViewResponse> => {
  if (!params.id || !params.accountId || !params.propertyId) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing advertiser ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    const response = await apollo().query({
      query: gql`
        query GetGaAvailablePropertyView($params: GaAvailablePropertyViewParams) {
          getGaAvailablePropertyView(params: $params) {
            data {
              id
              name
            }
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data.getGaAvailablePropertyView;
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, error.message);
    logError('getGaAvailablePropertyView', error);
  }
};

const getGaConnector = async ({ commit }, params: { id: string }): Promise<GaConnectorResponse> => {
  if (!params.id) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing advertiser ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    commit(CONSTANTS.SET_LOADING, true);
    const response = await apollo().query({
      query: gql`
        query GetGaConnector($params: GaConnectorParams) {
          getGaConnector(params: $params) {
            data {
              id
              primaryId
              additional
              ViewStatus
              RequestScope
              Healthy
              PictureUrl
              FirstName
              LastName
              Email
              ConnectionDate
            }
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data.getGaConnector;
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, error.message);
    logError('getGaConnector', error);
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const getGaAvailableProperty = async (
  { commit },
  params: { id: string; accountId: string },
): Promise<GaAvailablePropertyResponse> => {
  if (!params.id || !params.accountId) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing advertiser ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    const response = await apollo().query({
      query: gql`
        query GetGaAvailableProperty($params: GaAvailablePropertyParams) {
          getGaAvailableProperty(params: $params) {
            ga {
              id
              name
            }
            ga4 {
              id
              name
            }
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data.getGaAvailableProperty;
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, error.message);
    logError('getGaAvailableProperty', error);
  }
};

const deleteGATokenDataApi = async ({ commit }, params: { id: string }): Promise<string> => {
  if (!params.id) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing advertiser ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    commit(CONSTANTS.SET_LOADING, true);
    const response: ApolloQueryResult<string> = await apollo().mutate({
      mutation: gql`
        mutation DeleteGAToken($params: DeleteGaTokenParams) {
          deleteGaToken(params: $params)
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data;
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, error.message);
    logError('deleteGATokenDataApi', error);
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const addGaConnector = async (
  { commit },
  params: { id: string; accountId: string; propertyId: string; viewId?: string },
): Promise<AddGaConnectorResponse> => {
  if (!params.id || !params.accountId || !params.propertyId) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    commit(CONSTANTS.SET_LOADING, true);
    const response: ApolloQueryResult<AddGaConnectorResponse> = await apollo().mutate({
      mutation: gql`
        mutation AddGaConnector($params: AddGaConnectorParams) {
          addGaConnector(params: $params) {
            created
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data;
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, error.message);
    logError('addGaConnector', error);
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const deleteGaConnector = async (
  { commit },
  params: { id: string; connectorId: string },
): Promise<DeleteGaConnectorResponse> => {
  if (!params.id || !params.connectorId) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    commit(CONSTANTS.SET_LOADING, true);
    const response: ApolloQueryResult<DeleteGaConnectorResponse> = await apollo().mutate({
      mutation: gql`
        mutation DeleteGaConnector($params: DeleteGaConnectorParams) {
          deleteGaConnector(params: $params) {
            deleted
          }
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data;
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, error.message);
    logError('deleteGaConnector', error);
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const changeGaStatus = async ({ commit }, params: { advertiserId: string; gaStatus: string }): Promise<string> => {
  if (!params.advertiserId || !params.gaStatus) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing ID');
    return;
  }

  const { advertiserId, gaStatus } = params;
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);

    const response: AxiosResponse<any> = await axios.patch(`/api/advertiser/${advertiserId}/ga-status`, {
      gaStatus,
    });

    if (response.data && response.data?.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, response.data.error);
      return response.data;
    }
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, error.message);
    logError('changeEmailStatusForGa', error);
  }
};

const addGAToken = async ({ commit }, params: { id: string; token: string }): Promise<string> => {
  if (!params.id || !params.token) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_TOKEN_ERROR, 'Missing ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_TOKEN_ERROR, null);
    commit(CONSTANTS.SET_LOADING, true);
    const response: ApolloQueryResult<string> = await apollo().mutate({
      mutation: gql`
        mutation AddGaToken($params: AddGaTokenParams) {
          addGaToken(params: $params)
        }
      `,
      variables: {
        params: params,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data;
  } catch (error) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_TOKEN_ERROR, error.message);
    logError('addGAToken', error);
  } finally {
    commit(CONSTANTS.SET_LOADING, false);
  }
};

const googleAnalyticsStatus = async (
  { commit },
  payload: GoogleDataParams,
): Promise<GoogleAnalyticsAccount | Error> => {
  if (!payload.id) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing client ID');
    return;
  }
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_ANALYTICS_LOADING, true);
    const response: ApolloQueryResult<GetGoogleAnaltyicsStatusResponse> = await requests.getGoogleAnalyticsStatus(
      payload,
    );
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return response.data?.getGoogleAnalyticsStatus?.status;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.googleAnalyticsStatus');
  } finally {
    commit(CONSTANTS.SET_GOOGLE_ANALYTICS_LOADING, false);
  }
};

const googleAnalyticsAvailableProperties = async (
  { commit },
  payload: GoogleDataParams,
): Promise<GoogleAnaltyicsAvailableAccount[] | Error> => {
  if (!payload.id || !payload.accountId) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing client ID or account');
    return;
  }

  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    const response: ApolloQueryResult<GetGoogleAnalyticsAvailablePropertiesResponse> =
      await requests.getGoogleAnalyticsAvailableProperties(payload);
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return response.data?.getGoogleAnalyticsAvailableProperties?.WebProperties;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.googleAnalyticsAvailableProperties');
  }
};

const googleAnalyticsAvailableProfiles = async (
  { commit },
  payload: GoogleDataParams,
): Promise<GoogleAnaltyicsAvailableProfile[] | Error> => {
  if (!payload.id || !payload.accountId || !payload.profile) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, 'Missing client ID, account, or profile');
    return;
  }

  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    const response: ApolloQueryResult<GetGoogleAnalyticsAvailableProfilesResponse> =
      await requests.getGoogleAnalyticsAvailableProfiles(payload);
    if (response?.errors) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_ERROR, response.errors[0]?.message);
      return { message: response.errors[0]?.message };
    }
    return response.data?.getGoogleAnalyticsAvailableProfiles?.Profiles;
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.googleAnalyticsAvailableProfiles');
  }
};

const updateGoogleAnalyticsConfig = async ({ commit }, payload): Promise<object> => {
  try {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, null);
    commit(CONSTANTS.SET_GOOGLE_ANALYTICS_LOADING, true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req: AxiosResponse<any> = await axios.post(`/api/updateGoogleAnalyticsConfig/${payload.id}`, {
      config: payload.config,
    });
    if (req.data && req.data.error) {
      commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, req.data.error);
      return req.data;
    }
    // hide sensitive data
    return { success: true };
  } catch (exp) {
    commit(CONSTANTS.SET_LINEAR_ADMIN_GOOGLE_ANALYTICS_ERROR, exp.message);
    throw logError(exp, 'store.linearadmin.updateGoogleAnalyticsConfig');
  } finally {
    commit(CONSTANTS.SET_GOOGLE_ANALYTICS_LOADING, false);
  }
};

/* eslint-disable-next-line no-empty-pattern */
const sendGAEmailDataAPI = async ({}, options): Promise<object> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const req: AxiosResponse<any> = await axios.post(`/api/sendGAEmailDataAPI/${options.PropertyId}`, options);
  return req.data;
};

export default {
  sendGAEmailDataAPI,
  googleAnalyticsAvailableProfiles,
  googleAnalyticsAvailableProperties,
  updateGoogleAnalyticsConfig,
  googleAnalyticsStatus,
  deleteGATokenDataApi,
  addGAToken,
  getGaAvailableAccount,
  getGaAvailableProperty,
  getGaAvailablePropertyView,
  getGaConnector,
  addGaConnector,
  deleteGaConnector,
  changeGaStatus,
};
