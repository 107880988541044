import commonPkg from '@point/classes';
import { ContactsKeyType } from '@point/utility-classes';
import { State, DMA, CampaignType } from './types/filters';
import { parse, isValid } from 'date-fns';
import { format } from 'date-fns-tz';
import { Tooltip } from '../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import { Campaign } from './types/campaign';
import { CampaignDetails, Advertiser } from './store/modules/customer/types';
import { SavedRoute } from './types/util';
import { ThemeConfig } from './plugins/vuetify/themes/variables';
import { Product } from './types/advertisers.d';
import store from './store';
import jwt_decode from 'jwt-decode';

const { roles, tooltips } = commonPkg;
type tAHToken = typeof roles.AHToken;

const { METRIC_TOOLTIPS } = tooltips;

let origin = window.location.origin;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const env = (window as any)._dash_env;
const params = new URLSearchParams(location.search);
const originOverride = params.get('domainOverride');
if (originOverride) {
  origin = originOverride;
}

// has unit test
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ensureArray = (check: any): Array<any> => {
  if (typeof check === 'undefined' || check === null) {
    return [];
  }
  if (Array.isArray(check)) {
    return check;
  }
  return [check];
};

const isFullscreen = (): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doc: any = window.document;

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    return false;
  } else {
    return true;
  }
};

const toggleFullScreen = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const doc: any = window.document;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const docEl: any = doc.documentElement;

  const requestFullScreen =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  const cancelFullScreen =
    doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
};

const getTooltipsFromMetric = (metricName: string): Tooltip => {
  if (!metricName) return;
  let tooltip = null;
  const metric = metricName?.toLowerCase().replace(/\s+/gi, '');
  switch (metric) {
    case 'audience':
      tooltip = METRIC_TOOLTIPS.AUDIENCE;
      break;
    case 'spendval':
      tooltip = METRIC_TOOLTIPS.SPEND;
      break;

    case 'pageengagements':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_PAGE_ENGAGEMENTS;
      break;

    case 'costperclick':
      tooltip = METRIC_TOOLTIPS.CPC;
      break;

    case 'costpermille':
      tooltip = METRIC_TOOLTIPS.CPM;
      break;

    case 'goal':
      tooltip = METRIC_TOOLTIPS.GOAL;
      break;

    case 'clicktoviewrate':
      tooltip = METRIC_TOOLTIPS.CLICK_TO_VIEW_RATE;
      break;

    case 'clicks':
      tooltip = METRIC_TOOLTIPS.CLICKS;
      break;

    case 'clickthrough':
    case 'ctr':
      tooltip = METRIC_TOOLTIPS.CTR;
      break;

    case 'innovidxpimpressionshare':
      tooltip = METRIC_TOOLTIPS.INNOVIDXP_IMRESSION_SHARE;
      break;

    case 'pacing':
      tooltip = METRIC_TOOLTIPS.PACING;
      break;
    case 'impressiongoalpercent':
      tooltip = METRIC_TOOLTIPS.PERCENT_OF_GOAL;
      break;
    case 'vc100':
      tooltip = METRIC_TOOLTIPS.VC100;
      break;
    case 'vcr':
      tooltip = METRIC_TOOLTIPS.COMPLETION_RATE;
      break;
    case 'videoimpressions':
      tooltip = METRIC_TOOLTIPS.VIDEO_IMPRESSIONS;
      break;
    case 'otherdevicetype':
      tooltip = METRIC_TOOLTIPS.OTHER_DEVICE_TYPE;
      break;
    case 'csv/xlsx_export_device_type':
      tooltip = METRIC_TOOLTIPS.CSV_XLSX_EXPORT_DEVICE_TYPE;
      break;

    case 'completionrate':
      tooltip = METRIC_TOOLTIPS.COMPLETION_RATE;
      break;
    // CTV and Digital Video completion rate
    case 'ottcompletionrate':
      tooltip = METRIC_TOOLTIPS.OTT_COMPLETION_RATE;
      break;
    case 'digitalvideocompletionrate':
      tooltip = METRIC_TOOLTIPS.DVIDEO_COMPLETION_RATE;
      break;
    // Preroll Video completion rate
    case 'prerollcompletionrate':
      tooltip = METRIC_TOOLTIPS.PREROLL_COMPLETION_RATE;
      break;

    case 'conversionsott':
      tooltip = METRIC_TOOLTIPS.CONVERSIONS_OTT;
      break;

    case 'impressiongoal':
      tooltip = METRIC_TOOLTIPS.IMPRESSIONS_GOAL;
      break;

    case 'hours':
      tooltip = METRIC_TOOLTIPS.HOURS;
      break;
    case 'reach':
      tooltip = METRIC_TOOLTIPS.REACH;
      break;
    case 'frequency':
      tooltip = METRIC_TOOLTIPS.FREQUENCY;
      break;
    case 'searchimprshare':
      tooltip = METRIC_TOOLTIPS.SEARCH_IMP_SHARE;
      break;
    case 'conversions':
      tooltip = METRIC_TOOLTIPS.CONVERSIONS;
      break;
    case 'conversionrate':
      tooltip = METRIC_TOOLTIPS.CVR;
      break;
    case 'allconversions':
      tooltip = METRIC_TOOLTIPS.ALLCONVERSIONS;
      break;
    case 'gausers':
      tooltip = METRIC_TOOLTIPS.USERS;
      break;
    case 'sessions':
      tooltip = METRIC_TOOLTIPS.SESSIONS;
      break;
    case 'pageviews':
      tooltip = METRIC_TOOLTIPS.PAGE_VIEWS;
      break;
    case 'percentnewsessions':
      tooltip = METRIC_TOOLTIPS.NEW_SESSIONS_PERCENTAGE;
      break;
    case 'bouncerate':
      tooltip = METRIC_TOOLTIPS.BOUNCE_RATE;
      break;
    case 'avgsessionduration':
      tooltip = METRIC_TOOLTIPS.AVG_SESSION_DURATION;
      break;
    case 'pagespersession':
      tooltip = METRIC_TOOLTIPS.PAGES_PER_SESSION;
      break;
    case 'aired':
      tooltip = METRIC_TOOLTIPS.TIMES_AIRED;
      break;
    case 'visitsairing':
      tooltip = METRIC_TOOLTIPS.VISITS_AIRING;
      break;
    case 'visits':
      tooltip = METRIC_TOOLTIPS.VISITS;
      break;
    case 'geovisits':
      tooltip = METRIC_TOOLTIPS.STORE_VISITS;
      break;
    case 'viewrate':
      tooltip = METRIC_TOOLTIPS.VIEW_RATE;
      break;
    case 'hhimpressions':
      tooltip = METRIC_TOOLTIPS.HH_IMPRESSIONS;
      break;

    // facebook
    case 'pageactions':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_ACTIONS_ON_PAGE;
      break;
    case 'pagelikes':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_PAGE_LIKES;
      break;
    case 'postengagements':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_POST_ENGAGEMENTS;
      break;
    case 'videoviews':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_VIDEO_VIEWS;
      break;
    case 'pagefollowers':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_PAGE_FOLLOWERS;
      break;
    case 'socialctr':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_CTR;
      break;
    case 'socialclicks':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_CLICKS;
      break;
    case 'linkclicks':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_LINK_CLICKS;
      break;
    case 'linkclickthrough':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_LINK_CTR;
      break;
    case 'dmaname':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_GEO_DATA;
      break;
    case 'uniqueclicks':
      tooltip = METRIC_TOOLTIPS.FACEBOOK_UNIQUE_CLICKS;
      break;
    case 'liftperspot':
      tooltip = METRIC_TOOLTIPS.LIFT_SPOT;
      break;

    // CTV and digital video
    case 'ottdigitalvideoreach':
      tooltip = METRIC_TOOLTIPS.OTTDVIDEO_REACH;
      break;
    case 'ottdigitalvideofrequency':
      tooltip = METRIC_TOOLTIPS.OTTDVIDEO_FREQUENCY;
      break;

    // digital video
    case 'digitalvideoctr':
      tooltip = METRIC_TOOLTIPS.DVIDEO_CTR;
      break;
    case 'digitalvideoimp':
      tooltip = METRIC_TOOLTIPS.DVIDEO_IMPRESSIONS;
      break;

    case 'geocookieimpressions':
      tooltip = METRIC_TOOLTIPS.GEOCOOKIE_IMPRESSIONS;
      break;
    case 'geobehaviorimpressions':
      tooltip = METRIC_TOOLTIPS.GEOBEHAVIOR_IMPRESSIONS;
      break;
    case 'georetargetingimpressions':
      tooltip = METRIC_TOOLTIPS.GEORETARGETING_IMPRESSIONS;
      break;
    case 'views':
      tooltip = METRIC_TOOLTIPS.VIEWS;
      break;

    // youtube
    case 'spend':
      tooltip = METRIC_TOOLTIPS.SPEND;
      break;
    case 'averagecpv':
      tooltip = METRIC_TOOLTIPS.CPV;
      break;
    case 'engagements':
      tooltip = METRIC_TOOLTIPS.GOOGLEVIDEO_ENGAGEMENTS;
      break;
    case 'engagementrate':
      tooltip = METRIC_TOOLTIPS.GOOGLEVIDEO_ENGAGEMENT_RATE;
      break;
    case 'googlevideo100%viewed':
      tooltip = METRIC_TOOLTIPS.GOOGLEVIDEO_100PERCENT_VIEWED;
      break;
    case 'googlevideoviewrate':
      tooltip = METRIC_TOOLTIPS.GOOGLEVIDEO_VIEW_RATE;
      break;
    case 'googlevideoctr':
      tooltip = METRIC_TOOLTIPS.GOOGLEVIDEO_CTR;
      break;
    case 'costperview':
      tooltip = METRIC_TOOLTIPS.CPV;
      break;

    case 'emailviews':
      tooltip = METRIC_TOOLTIPS.EMAIL_VIEWS;
      break;

    case 'other':
      tooltip = METRIC_TOOLTIPS.OTHER;
      break;

    // tvsquared
    case 'tvsquaredspendval':
    case 'innovidxpspendval':
      tooltip = METRIC_TOOLTIPS.TVSQUARED_TOTAL_MEDIA_SPEND;
      break;
    case 'airings':
    case 'innovidxpairings':
      tooltip = METRIC_TOOLTIPS.TVSQUARED_AIRINGS;
      break;
    case 'cpm':
    case 'innovidxpcpm':
      tooltip = METRIC_TOOLTIPS.TVSQUARED_CPM;
      break;
    case 'tvsquaredimps':
    case 'innovidxpimpressions':
      tooltip = METRIC_TOOLTIPS.TVSQUARED_HOUSEHOLD_IMPRESSIONS;
      break;
    case 'responsenumber':
    case 'innovidxpresponsenumber':
      tooltip = METRIC_TOOLTIPS.TVSQUARED_RESPONSES_FROM_TV;
      break;
    case 'costperresponse':
    case 'innovidxpcostperresponse':
      tooltip = METRIC_TOOLTIPS.TVSQUARED_COST_PER_RESPONSE;
      break;
    case 'responsepercent':
    case 'innovidxpresponsepercent':
      tooltip = METRIC_TOOLTIPS.TVSQUARED_RESPONSE_PERCENTAGE;
      break;
    // summary
    case 'lastupdated':
      tooltip = METRIC_TOOLTIPS.LAST_UPDATED;
      break;
    case 'campaignsummarydelayed':
      tooltip = METRIC_TOOLTIPS.CAMPAIGN_SUMMARY_DELAYED;
      break;
    // GroundTruth
    case 'gtreach':
      tooltip = METRIC_TOOLTIPS.GROUNDTRUTH_REACH;
      break;
    case 'gtfrequency':
      tooltip = METRIC_TOOLTIPS.GROUNDTRUTH_FREQUENCY;
      break;
  }
  return tooltip;
};

// has unit test
const dataFillTooltips = (
  tooltips: Array<Tooltip> | null,
  tokens: Array<{ key: string; value: string | number }>,
): Array<Tooltip> => {
  // looks for replacement tokens wrapped in {}
  // allows the tooltips to have data driven values
  if (Array.isArray(tooltips)) {
    const x = tooltips.map((tooltip: Tooltip) => {
      tokens.forEach(token => {
        if (tooltip?.message) tooltip.message = tooltip.message.replace(`{${token.key}}`, token.value.toString());
      });
      return tooltip;
    });
    return x;
  }
  return [];
};

// has unit test
const dataFillTooltipsHelper = function (dataSource: string, tips: Array<Tooltip>): Array<Tooltip> {
  if (dataSource.includes('SEM.BySearchImpression')) {
    tips.push({
      title: 'Content',
      message:
        'The Google Ads API registers all activity under their Dynamic Search Ads (DSA), not associated to specific search terms, as “Content”.',
    });
  }
  if (dataSource.includes('GAMDISPLAY.ByAppImpression') || dataSource.includes('GAMVIDEO.ByAppImpression')) {
    tips.push({
      title: '(Not Applicable)',
      message:
        `Video ads that are not targeted to specific apps using the "msid" and "an" parameters are reported by Google Ad Manager as <br /> "(Not applicable)". ` +
        `If all or most of the impression served appear here as <br /> "(Not applicable)", that means that your video ads were served on any available and eligible apps.`,
    });
  }
  if (dataSource.includes('GAMVIDEO.ByVideoContentImpression')) {
    tips.push({
      title: '(Not Applicable)',
      message:
        `Video ads that are not targeted to specific apps using the "msid" and "an" parameters are reported by Google Ad Manager as <br /> "(Not applicable)". ` +
        `If all or most of the impression served appear here as <br /> "(Not applicable)", that means that your video ads were served on any available and eligible content.`,
    });
  }
  return tips;
};

// has unit test
const formatNumberWithCommas = (number: number | string): string => {
  return String(number).replace(/(^|[^\w.])(\d{4,})/g, ($0, $1, $2) => {
    return $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, '$&,');
  });
};

const addNumbersWithCommas = (num1: string, num2: string): string => {
  num1 = num1.replaceAll(',', '');
  num2 = num2.replaceAll(',', '');
  const sum = parseInt(num1) + parseInt(num2);
  return sum.toLocaleString('en-US');
};

// has unit test
const formatValueEstimate = (value: number | string): string => {
  let val;
  if (value === undefined) {
    val = '0';
  } else if (typeof value === 'number' && value < 1) {
    val = value.toString();
  }
  // handle decimals
  else if (typeof value === 'number') {
    if (value > 999) {
      // if over 1 million, convert to a decimal, e.g. (12,000,000 becomes 12M)
      const denom = value < 1000000 ? 1000 : 1000000;
      val = `${(value / denom).toFixed(1)}${value < 1000000 ? 'K' : 'M'}`;
    } else {
      val = formatNumberWithCommas(value);
    }
  } else {
    val = value;
  }
  return val;
};

// has unit test
const formatFloatToPercent = (num: string): string => {
  let percent = `${(parseFloat(num) * 100).toFixed(2)}%`.replace('.00', '');
  const checkForTrailingZero = new RegExp(/\.[0-9]+0%/);
  if (checkForTrailingZero.test(percent)) {
    // avoid trailing 0s on the decimal (e.g. 2.10%)
    const [left, right] = percent.split('.');
    percent = `${left}.${right.replace('0%', '%')}`;
  }
  return percent;
};

// has unit test
const getDaypartLabel = (daypart: string): string => {
  let label = `${daypart} `;
  switch (daypart.toLocaleLowerCase()) {
    case 'early morning':
      label += '(5am-9am)';
      break;
    case 'daytime':
      label += '(9am-3pm)';
      break;
    case 'early fringe':
      label += '(3pm-5pm)';
      break;
    case 'early news':
      label += '(5pm-7pm)';
      break;
    case 'prime':
      label += '(7pm-8pm)';
      break;
    case 'prime access':
      label += '(8pm-11pm)';
      break;
    case 'late news':
      label += '(11pm-11:30pm)';
      break;
    case 'late fringe':
      label += '(11:30pm-2am)';
      break;
    case 'overnight':
      label += '(2am-5am)';
      break;
    default:
      break;
  }
  return label;
};

// has unit test
const formatHour = (hour: number, fullHour: boolean): string => {
  if (typeof hour === 'string') {
    hour = parseInt(hour, 10);
  }

  const timeOfDay = hour >= 0 && hour < 12 ? 'am' : 'pm';
  const full = fullHour ? ':00' : '';

  // convert from military to standard
  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0) {
    hour = 12;
  }

  return `${hour}${full}${timeOfDay}`;
};

// has unit test
const reverseFormatHour = (hourString: string): number => {
  const timeOfDay = hourString.toLowerCase().slice(-2); // gets 'am' or 'pm'
  let hourMilitary = 0;
  // ignores format '11:00am', current formatHour doesn't support times like 11:30am, so this shouldn't be an issue
  hourMilitary = parseInt(hourString.slice(0, 2), 10); // parseint will ignore the non-numeral character in results like '1p'from '1pm'

  // convert from standard to military
  if (hourMilitary === 12) {
    // midnight/noon exception
    hourMilitary = timeOfDay === 'am' ? 0 : 12;
  } else if (timeOfDay === 'pm') {
    hourMilitary += 12;
  }
  return hourMilitary;
};

// has unit test
const enabledIcon = (state: string | null | undefined): string => {
  return state === '1' ? 'visibility' : 'visibility_off';
};

// has unit test
const enabledColor = (state: string | null | undefined): string => {
  return state === '1' ? 'green' : 'orange';
};

// has unit test
const enabledText = (state: string | null | undefined): string => {
  return state === '1' ? 'Deactivation' : 'Activation';
};

// has unit test
const createdModifiedDate = (date: string): string => {
  let parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss.SSS a', new Date()); // parse custom date format
  if (isValid(parsedDate)) return format(parsedDate, 'MM/d/yy hh:mm a');
  else parsedDate = parse(date, 'MM/dd/yyyy hh:mm:ss a', new Date()); // parse custom date format
  return format(parsedDate, 'MM/d/yy hh:mm a');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fireOnAdDataChange = (component: any, callback: any, delayed = false): any => {
  if (!component.sectionConfig && !component.componentConfig) {
    // eslint-disable-next-line no-console
    console.log('Warning, component does not have sectionConfig/componentConfig', component);
  }

  let mutationName = 'SET_AD_PERFORMANCE';
  if (component.sectionConfig?.allTime) {
    mutationName = 'SET_ALL_TIME_AD_PERFORMANCE';
  } else if (component.sectionConfig?.enableCampaignSelection) {
    mutationName = 'SET_CAMPAIGN_AD_PERFORMANCE';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return component.$store.subscribe((mutation: any) => {
    switch (mutation.type) {
      case mutationName:
        if (delayed) {
          component.$nextTick().then(callback);
          // console.log('delayed fireOnAdDataChange');
          // setTimeout(callback, 100);
        } else {
          callback();
        }
        break;
      default:
        break;
    }
  });
};

// has unit test
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isWaitingOnData = (component: any): boolean => {
  if (!component.sectionConfig && !component.componentConfig) {
    // eslint-disable-next-line no-console
    console.log('Warning, component does not have sectionConfig/componentConfig', component);
  }

  let isWaiting = component.$store.state.customer.loadingAdPerformance;
  if (component.sectionConfig?.allTime) {
    isWaiting = component.$store.state.customer.loadingAllTimeAdPerformance;
  } else if (component.componentConfig?.useTacticSummaryData) {
    isWaiting = component.$store.state.customer.loadingTacticSummaryPerformance;
  } else if (component.sectionConfig?.enableCampaignSelection) {
    isWaiting = component.$store.state.customer.loadingCampaignAdPerformance;
  }
  return isWaiting;
};

// has unit test
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const propertyByPath = (obj: object | null | undefined, path: string): any => {
  /**
   * Takes an object and a period separated string representing a path.
   *
   * @param {
   *   myobject: {
   *     property: 'test
   *   }
   * }
   * @param 'myobject.property'
   * @return 'test'
   */

  // console.log('propertyByPath', obj, path);

  if (!obj || !path) {
    return null;
  }
  const paths = path.split('.');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let current: any = obj;
  let i: number;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    }
    current = current[paths[i]];
  }
  return current;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const adDataForKey = (component: any, path: string = null): any => {
  if (!component.sectionConfig && !component.componentConfig) {
    // eslint-disable-next-line no-console
    console.log('Warning, component does not have sectionConfig/componentConfig', component);
  }

  let pathValue = path;
  let externalPath = '';

  if (component.isExporting) {
    let source = component.$options.propsData.exportData.adData;
    if (component.componentConfig?.useTacticSummaryData) {
      // check if tacticsSummary exists
      if (component.$options.propsData.exportData.tacticsSummary) {
        source = component.$options.propsData.exportData.tacticsSummary;
      }
    }
    if (!path || path.length === 0) {
      return source;
    }

    const hasPathInternalData = path.split('.').length > 2;
    if (hasPathInternalData) {
      pathValue = path.split('.').splice(0, 2).join('.');
      externalPath = path.split('.').pop();
    }

    let data = propertyByPath(source, pathValue);
    if (externalPath.length) {
      if (data !== undefined) {
        data = data[externalPath];
      } else {
        data = null;
      }
    }
    if (
      !component.componentConfig?.additionalDataSource ||
      pathValue.includes('Total') ||
      pathValue === 'LastModifiedDate'
    ) {
      return data;
    }

    const additionalData = propertyByPath(source, component.componentConfig?.additionalDataSource);
    if (!additionalData) return data;

    // TODO: come up with more universal solution
    const key = component.componentConfig?.cid.toLowerCase().includes('creative') ? 'CreativeId' : 'Day';
    const mergedData = mergeData(
      data,
      additionalData,
      component.componentConfig?.additionalMetrics,
      key,
      propertyByPath(source, 'OTTTotal'),
    );
    return mergedData;
  }

  // performance data for all campaigns for the current date range
  let source = component.$store.state.customer.adPerformance;

  if (component.sectionConfig?.allTime) {
    // performance data for all campaigns over all time
    source = component.$store.state.customer.allTimeAdPerformance;
  } else if (component.componentConfig?.useTacticSummaryData) {
    // check if it's a summary page first.
    source = component.$store.state.customer.tacticSummaryPerformance;
  } else if (component.sectionConfig?.enableCampaignSelection) {
    // performance data for one or more campaigns
    source = component.$store.state.customer.campaignAdPerformance;
  } else if (component.sectionConfig?.useSummaryData) {
    source = component.$store.state.customer.summaryPerformance;
  }
  if (!path || path.length === 0) {
    return source;
  }

  const hasPathInternalData = path.split('.').length > 2;
  if (hasPathInternalData) {
    pathValue = path.split('.').splice(0, 2).join('.');
    externalPath = path.split('.').pop();
  }

  let data = propertyByPath(source, pathValue);
  if (externalPath.length) {
    if (data !== undefined) {
      data = data[externalPath];
    } else {
      data = null;
    }
  }
  if (
    !component.componentConfig?.additionalDataSource ||
    pathValue.includes('Total') ||
    pathValue === 'LastModifiedDate'
  ) {
    return data;
  }

  const additionalData = propertyByPath(source, component.componentConfig?.additionalDataSource);
  if (!additionalData) return data;
  const key = component.componentConfig?.cid.toLowerCase().includes('creative') ? 'CreativeId' : 'Day';
  const mergedData = mergeData(
    data,
    additionalData,
    component.componentConfig?.additionalMetrics,
    key,
    propertyByPath(source, 'OTTTotal'),
  );
  return mergedData;
};

const mergeData = (initialData: [obj], additionalData: [any], fields: string[], key: string, total) => {
  const mergedData = initialData.map(item => {
    const idField = key;
    const additionalItem = additionalData.find(i => i[idField] === item[idField]);
    if (additionalItem) {
      for (const field of fields) {
        item[field] = additionalItem[field];
        item.isEnriched = true;
      }
      if (item?.Index) {
        item.Index = formatIndex(item, total);
      }
    }
    return item;
  });
  return mergedData;
};

// DASH-4703: calculate Index for OTT+InnovidXP
const formatIndex = (obj: any, total: any) => {
  const totalImps = total?.Impressions;
  const creativeImpression = obj?.Impressions;
  const creativeResponseShare = obj?.ResponseShare;
  const calculatedIndex = creativeResponseShare / (creativeImpression / totalImps);
  return `${Math.round(calculatedIndex)}%`;
};

// has unit test
const stringFormat = (str: string, format: string): string => {
  /**
  * Transforms string for mutliple scenerios
  * this can span multiple lines.
  .*
  * @param {String} str number
  * @param {String} format regex string
  * @return {String} Returns formated number
  */

  let output: string | number = '';

  if (format.includes('%s')) {
    output = format.replace('%s', str);
  } else if (format.includes('%n')) {
    const num = parseInt(str, 10);
    if (num >= 1000000) {
      output = `${(num / 1000000).toFixed(2)} M`;
    } else {
      output = format.replace('%n', formatNumberWithCommas(Number(num)));
    }
  } else if (format.includes('%h')) {
    output = format.replace('%h', formatHour(Number(str), false));
  } else if (format.includes('%d')) {
    output = format.replace('%d', parseFloat(str).toFixed(2));
  } else if (format.includes('%P1000')) {
    output = format.replace('%P1000', `${str}‰`);
  } else if (format.includes('%P')) {
    if (str) output = format.replace('%P', str);
  } else if (format.includes('%$')) {
    output = format.replace('%$', str.split('.')[0]);
  } else {
    output = str;
  }

  return output;
};

const addImageProcess = (src): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

// has unit test
const selectedDMAs = (stateList: State[] | null | undefined): string[] => {
  if (!stateList) return [];
  const reducedDMAs = stateList.reduce((sum1: string[], state: State) => {
    state.dmas.forEach((dma: DMA) => {
      if (dma.checked) sum1.push(dma.label);
    });
    return sum1;
  }, []);

  return reducedDMAs;
};

// has unit test
const selectedTypes = (types: CampaignType[] | null | undefined): string[] => {
  if (!types) return [];
  const reducedTypes = types?.reduce((sum1: string[], type: CampaignType) => {
    if (type.checked && !!type.campaigns) sum1.push(type.label);
    return sum1;
  }, []);

  return reducedTypes;
};

const sinclairSites = ['sbg', 'driveauto', 'compulse', 'myanalyticshub', 'sbgbackup'];

// has unit test
const isSinclair = (theme: ThemeConfig): boolean => {
  return !!(theme && sinclairSites.includes(theme.name));
};

// has unit test
const getEnvironment = (origin: string, env: string | null | undefined): string => {
  let _env = env;
  if (_env) {
    return _env;
  }
  const local = /\b(localhost)|(127\.0\.0\.1)|(local)\b/;
  const dev = /(dev\.)/;
  const staging = /(staging\.)|(stg\.)/;
  if (local.test(origin)) _env = 'local';
  else if (dev.test(origin)) _env = 'dev';
  else if (staging.test(origin)) _env = 'staging';
  else _env = 'production';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)._dash_env = _env;
  return _env;
};

const isDevelopment = (): boolean => {
  return getEnvironment(origin, env) === 'dev' || getEnvironment(origin, env) === 'local';
};

const isStaging = (): boolean => {
  return getEnvironment(origin, env) === 'staging';
};

const isProduction = (): boolean => {
  return getEnvironment(origin, env) === 'production';
};

const isLocalDev = (): boolean => {
  return getEnvironment(origin, env) === 'local';
};

const getAgencyFromURL = (host: string = window.location.host): string => {
  const parts = host.split('.');
  const agencyDomainPart = parts[0];
  return agencyDomainPart || 'compulse360';
};

const getEnvForProductLink = (getLocal = false) => {
  const originArr = origin.split('.');
  const isLocalDev = /localhost|127.0.0.1/gi.test(window.location.host);
  if (getLocal === true && (isLocalDev || originArr.includes('local'))) return 'local';
  if (originArr.includes('dev') || originArr.includes('local') || isLocalDev) return 'dev';
  if (originArr.includes('stg')) return 'stg';
  return 'prod';
};

const getEnvForProductLinkOld = () => {
  const isLocalDev = /localhost|127.0.0.1/gi.test(window.location.host);
  if (/\bdev\b/.test(origin) || /\blocal\b/.test(origin) || isLocalDev) return 'dev';
  if (/\b(stg|staging)\b/.test(origin)) return 'stg';
  return 'prod';
};

// has unit test
const isDemoAdvertiser = (advertiser: Advertiser): string => {
  if (!advertiser.Name || !advertiser.Agency) return null;
  return advertiser.Name.toLowerCase() === 'demo advertiser' ? 'Demo' : advertiser.Agency;
};

// has unit test
const isUnknownOrOtherOrInvalidString = (fieldValue: string | null, options: null): boolean => {
  if (typeof fieldValue !== 'string' || !fieldValue || fieldValue.length === 0) {
    return true;
  }

  const low = fieldValue.toLowerCase();

  if (options?.otherOnly) {
    return low === 'other';
  }

  if (low.startsWith('other') || low.startsWith('unknown')) {
    return true;
  }

  return false;
};

// has unit test
const hexToRGBA = (hex: string | null | undefined, opacity?: number): string => {
  if (!hex || hex.length !== 7) return '';
  const hexR = hex.slice(1, 3);
  const hexG = hex.slice(3, 5);
  const hexB = hex.slice(5, 7);
  const a = opacity || 1;

  return `rgba(${parseInt(hexR, 16)},${parseInt(hexG, 16)},${parseInt(hexB, 16)},${a})`;
};

async function getLogo(
  theme: ThemeConfig,
): Promise<{ name: string; file?: string; img?: HTMLImageElement; emailLogo?: string }> {
  const logo = { name: theme.companyName, img: null, ...theme.logo };
  // check if image exists
  if (logo.file) {
    try {
      // this path doesnt exist right now
      logo.img = await addImageProcess(`https://cdn-dashboard.adportal.io/dashboard/companyLogos/${logo.file}`);
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.info('cant retrieve logo img');
    }
  }

  return logo;
}

const ValidationRules = {
  name: [
    (v: string): boolean | string => !!v || 'Name is required',
    (v: string): boolean | string => v.length >= 3 || 'Name must be more than 3 characters',
  ],
  email: [
    (v: string): boolean | string => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !v || pattern.test(v) || 'Invalid e-mail';
    },
  ],
  phone: [
    (v: string): boolean | string => !!v || 'Phone is required.',
    (v: string): boolean | string => {
      const pattern =
        /^[\\(]{0,1}([0-9]){3}[\\)]{0,1}[ ]?([^0-1]){1}([0-9]){2}[ ]?[-]?[ ]?([0-9]){4}[ ]*((x){0,1}([0-9]){1,5}){0,1}$/;
      return pattern.test(v) || 'Invalid phone';
    },
  ],
  number: [
    (v: number): boolean | string => !!v || 'Number is required',
    (v: number): boolean | string => v >= 0 || 'Number must be greater than 0',
  ],
};

const iconLibrary = {
  '': 'adjust',
  other: 'notes',
  'set top box': 'scanner',
  'connected tv': 'tv',
  'devices streaming video content to tv screens': 'tv',
  'game console': 'gamepad',
  gameconsole: 'gamepad',
  game: 'gamepad',
  desktop: 'desktop_mac',
  computers: 'desktop_mac',
  'desktops and laptops': 'desktop_mac',
  unknown: 'help_outline',
  undetermined: 'help_outline',
  'android phone': 'smartphone',
  mobile: 'smartphone',
  'mobile devices with full browsers': 'smartphone',
  'mobile web': 'smartphone',
  'mobile app': 'smartphone',
  ipad: 'tablet',
  tablet: 'tablet',
  tablets: 'tablet',
  'tablets with full browsers': 'tablet',
  'single-board computer': 'developer_board',
  'embedded network module': 'memory',
  'data colleciton terminal': 'device_hub',
  male: 'fa-male',
  female: 'fa-female',
  facebook: 'fa-facebook-square',
  messenger: 'fa-facebook',
  instagram: 'fa-instagram',
  twitter: 'fa-twitter',
  youtube: 'fa-youtube',
  ipod: 'phone_iphone',
  iphone: 'phone_iphone',
  chrome: 'fa-chrome',
  safari: 'fa-safari',
  ie: 'fa-internet-explorer',
  firefox: 'fa-firefox',
};

const metricIconMap = {
  CityId: 'location_on',
  GeoFenceName: 'location_on',
  State: 'location_on',
  Zipcode: 'location_on',
  RSN: 'location_on',
  Metro: 'location_city',
  City: 'place',
  Domain: 'language',
  App: 'fa-th',
  AppName: 'fa-th',
  VideoContent: 'ondemand_video',
  SearchKey: 'search',
  Keyword: 'search',
  Station: 'wifi_tethering',
  URL: 'web_asset',
  Page: 'link',
  Source: 'link',
  PageTitle: 'title',
  EventCategory: 'event',
  Date: 'call',
  CampaignName: 'video_library',
  Message: 'comment',
  Device: 'devices',
};

const metricIconMapC360 = {
  city: 'Map_Pin',
  CityId: 'Map_Pin',
  GeoFenceName: 'Map_Pin',
  State: 'Map_Pin',
  Zipcode: 'Map_Pin',
  RSN: 'Map_Pin',
  Metro: 'Building_04',
  City: 'Map_Pin',
  Domain: 'Globe',
  App: 'More_Grid_Big',
  AppName: 'More_Grid_Big',
  VideoContent: 'Monitor_Play',
  SearchKey: 'Search_Magnifying_Glass',
  Keyword: 'Search_Magnifying_Glass',
  Station: 'Wifi_High',
  URL: 'Window',
  Page: 'Link',
  Source: 'Link',
  PageTitle: 'Text',
  EventCategory: 'Calendar_Event',
  Date: 'Calendar_Days',
  CampaignName: 'Chromecast',
  Message: 'Chat',
  Device: 'Devices',
};
// Sidebar tactics mapping
const tacticObject = {
  system: 'All Products',
  summary: 'Summary',
  home: 'Home',
  orderlist: 'Order List',
  ordersummary: 'Order Summary',
  broadcast: 'TV',
  broadstreet: 'Broadstreet - O&O',
  calltracking: 'Call Tracking',
  display: 'Display',
  video: 'Digital Video (Video + OTT)',
  digitalvideo: 'Digital Video (Video + OTT)',
  emailmarketing: 'Email Marketing',
  emailsi: 'Email Marketing',
  siteimpact: 'Email Marketing',
  facebook: 'Facebook Ads',
  facebookads: 'Facebook Ads',
  facebookorganic: 'Facebook Organic',
  fbinsights: 'Facebook Organic',
  ga: 'Google Analytics',
  gam: 'Google Ad Manager',
  gamdisplay: 'Display - O&O',
  'display-gam': 'Display - GAM',
  gamvideo: 'Video - O&O',
  'video-gam': 'Video - GAM',
  'gam-sponsorship': 'GAM - Sponsorship',
  'gam-standard': 'GAM - Standard',
  googleanalytics: 'Google Analytics',
  googlesearchconsole: 'Google Search Console',
  googlesearch: 'Google Search Console',
  googlevideo: 'YouTube',
  gtdisplay: 'Geofence - Display',
  'geofence-display': 'Geofence - Display',
  gtvideo: 'Geofence - Video',
  'geofence-video': 'Geofence - Video',
  linear: 'Sinclair RSN',
  login: 'Login Portal',
  ott: 'CTV',
  amazonott: 'OTT',
  preroll: 'Video',
  sem: 'SEM',
  simpdisplay: 'Display',
  simpgeofence: 'Geofence',
  simpott: 'OTT',
  simppreroll: 'Preroll',
  sinclairrsn: 'Sinclair RSN',
  social: 'Facebook Ads',
  audio: 'Audio',
  magniteaudio: 'Audio',
  tv: 'TV',
  tv2ott: 'InnovidXP',
  tvsquared: 'InnovidXP',
  youtube: 'YouTube',
  trugeofence: 'True Geo',
};

// list of tactic names in lowercase without spaces for mapping with icons
const parsedTacticNames = [
  'display',
  'video',
  'ottctv',
  'digitalvideovideoott',
  'youtube',
  'facebookads',
  'sem',
  'tv',
  'display-oo',
  'video-oo',
  'sinclairrsn',
  'audio',
  'emailmarketing',
  'calltracking',
  'broadstreet-oo',
  'truegeo',
];

// has unit test
const getTacticName = (tactic: string | null | undefined): string => {
  let name = '';
  if (!tactic) return name;

  const t = tactic.toLowerCase().replace(/\s/g, '');
  if (tacticObject[t]) {
    name = tacticObject[t];
  } else {
    // properly capitalize unusual tactic names
    name = tactic
      .split('_')
      .map((n: string) => {
        return `${n.charAt(0)}${n.slice(1, n.length).toLowerCase()}`;
      })
      .join(' ');
  }
  return name;
};

// has unit test
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSetOfTactics = (component: any, source: { filterBy?: string; dataSource: string }): Array<any> => {
  // check if filterBy is set to tactic.
  if (!source?.filterBy || source?.filterBy.toLowerCase() !== 'tactic') return null;

  // Get a single list of available tactics.
  const data = adDataForKey(component, source.dataSource);
  const tactics = data.map((x: { Tactic: string }): string => x?.Tactic);

  return Array.from(new Set(tactics));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createTabArray = (arrTabs: Array<string>, validSections: Array<any>): Array<any> => {
  if (!validSections) return;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tempArr: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arrTabs.forEach((t: any) => {
    let progIndex = validSections.findIndex(x => x.id.toLowerCase() === t);
    if (t === 'GoogleSearchAds') {
      progIndex = validSections.findIndex(x => x.id.toLowerCase() === 'sem');
    } else if (t === 'Facebook') {
      progIndex = validSections.findIndex(x => x.id.toLowerCase() === 'social');
    }
    if (progIndex !== -1) {
      const tab = validSections[progIndex];
      // add friendly name to object
      const tabToAdd = {
        ...tab,
        friendlyName: getTacticName(tab?.id),
      };
      tempArr.push(tabToAdd);
    }
  });
  return tempArr;
};

// has unit test
const groupTactics = (validSections: Array<object> | null): Array<Product> => {
  if (!validSections) return [];

  const navArr: Array<Product> = [];
  // make arrays of all section ids
  const programmaticTabs = [
    'display',
    'simpdisplay',
    'gamdisplay',
    'preroll',
    'simppreroll',
    'gamvideo',
    'video',
    'sportsvideo',
    'ott',
    'simpott',
    'simpgeofence',
    'gtdisplay',
    'gtvideo',
    'trugeofence',
    'audio',
    'broadstreet',
  ];
  const paidSearchTabs = ['GoogleSearchAds']; // Current SEM tab
  const attributionTabs = ['calltracking', 'ga', 'googlesearch', 'alphonso', 'linear', 'tvsquared', 'tv2ott'];
  const socialTabs = ['Facebook', 'googlevideo']; // facebook is the current Social tab
  const emailTabs = ['emailsi', 'siteimpact'];
  const broadcastTabs = ['broadcast'];

  const tabArray = [programmaticTabs, paidSearchTabs, attributionTabs, socialTabs, emailTabs, broadcastTabs];
  const tabNames = ['Programmatic', 'Paid Search', 'Attribution', 'Social', 'E-mail', 'Broadcast'];
  tabArray.forEach((tab: string[], index) => {
    const tempArr = createTabArray(tab, validSections);
    if (tempArr.length > 0) {
      navArr.push({ id: tabNames[index], tabArray: tempArr });
    }
  });
  return navArr;
};

const headerNamesMap = (h: string): string => {
  const map = {
    a18to49impressions: 'Age 18-49 imps',
    a25to54impressions: 'Age 25-54 imps',
    ac25: '25% Listened',
    ac50: '50% Listened',
    ac75: '75% Listened',
    ac100: '100% Listened',
    actions: 'Actions',
    aired: 'Times Aired',
    airings: 'Airings',
    allconversions: 'All Conversions',
    avgsessionduration: 'Avg. Session Duration',
    bouncerate: 'Bounce Rate',
    campaignname: 'Campaign Name',
    city: 'City',
    cityid: 'City',
    clickthrough: 'CTR',
    clickrate: 'Click Rate',
    clicktoviewrate: 'Click To View Rate',
    completionrate: 'VCR',
    conversionrate: 'CVR',
    conversioncategory: 'Conversion Category',
    conversionaction: 'Conversion Action',
    conversionsource: 'Conversion Source',
    costperclick: 'CPC',
    costpercreative: 'Cost',
    costpermille: 'CPM',
    costperresponse: 'Cost per Response',
    costperview: 'Average CPV',
    creativeid: 'Creative ID',
    creativename: 'Creative Name',
    creativevideo: 'Creative Ad',
    durationavg_processedcalls: 'Avg. duration',
    dmaname: 'DMA',
    dmacode: 'DMA',
    enddate: 'End Date',
    estimatenumber: 'Order',
    gausers: 'Total Users',
    geocookieimpressions: 'Historical Imps',
    geobehaviorimpressions: 'Behavioral Imps',
    georetargetingimpressions: 'Retargeting Imps',
    geofencingimpressions: 'Geofencing Imps',
    geofencename: 'Geofence',
    geovisits: 'Store Visits',
    hhimpressions: 'Household imps',
    hours: 'Viewing Hours',
    impressiongoal: 'Imps Goal',
    impressionpercent: 'Imp %',
    impressiongoalpercent: '% of Goal',
    impressions: 'Imps',
    impressionshare: 'Imp Share',
    impressionsharenumber: 'Imp Share',
    innovidxpestimatedsearchimpressions: 'Estimated Search Impressions',
    innovidxpimpressionshare: 'Impression Share',
    innovidxpmarkuprate: 'Markup Rate',
    innovidxpspendval: 'Total Media Spend',
    innovidxpairings: 'Airings',
    innovidxpcpm: 'CPM',
    innovidxpimpressions: 'Household Impressions',
    innovidxpresponsenumber: 'Responses',
    innovidxpcostperresponse: 'CPR',
    innovidxpresponsepercent: 'Response Rate',
    iscicode: 'ISCI Code',
    isprimary: 'Primary',
    lifetimeaired: 'Aired',
    lifetimeaudience: 'Audience',
    lifetimeclicks: 'Clicks',
    lifetimeclickthrough: 'CTR',
    lifetimeclicktoviewrate: 'CTVR',
    lifetimeconversionrate: 'Conv rate',
    lifetimeconversions: 'Convs',
    lifetimecostperclick: 'CPC',
    lifetimehours: 'Hours',
    lifetimeimpressions: 'Imps',
    lifetimespendval: 'Spend',
    lifetimevc100: '100% Viewed',
    lifetimeviews: 'Views',
    lifetimevisits: 'Visits',
    lifetimevisitsairing: 'Visits per airing',
    linkclicks: 'Link Clicks',
    linkclickthrough: 'Link CTR',
    name: 'Site',
    otthour: 'Total Hours',
    ottvc100: '100% Viewed',
    ottvc25: '25% Viewed',
    ottvc50: '50% Viewed',
    ottvc75: '75% Viewed',
    pacing: 'Pacing',
    pageactions: 'Page Actions',
    pageengagements: 'Page Engagements',
    pagefollowers: 'Page Followers',
    pagelikes: 'Page Likes',
    pagespersession: 'Pages per Session',
    pageviews: 'Screen Page Views',
    percentnewsessions: 'New Sessions',
    percentage: '% of Household Impressions',
    postengagements: 'Post Engagements',
    postreach: 'Post Reach',
    processedcalls: 'Processed Calls',
    program: 'Programs',
    responsenumber: 'Responses',
    responserate: 'Response Rate',
    responsepercent: 'Response Percentage',
    responses: 'TV Responses',
    responseshare: 'Response Share',
    searchimprshare: 'Search Imp Share',
    searchkey: 'Search Term',
    searchlostisbudget: 'Lost IS Budget',
    searchlostisrank: 'Lost IS Rank',
    spendval: 'Spend',
    startdate: 'Start Date',
    station: 'TV Stations',
    storevisits: 'Store Visits',
    totalprocessedcalls: 'Calls',
    trackingnumber: 'Tracking Phone Number',
    url: 'URL',
    vc25: '25% Viewed',
    vc50: '50% Viewed',
    vc75: '75% Viewed',
    vc100: '100% Viewed',
    vcr: 'VCR',
    videoengagementrate: 'Engagement rate',
    videoengagements: 'Engagements',
    videoimpressions: 'Video Impressions',
    videoviews: 'Video Views',
    viewrate: 'View Rate',
    visitsairing: 'Visits / Airing',
    visitsgoal: 'Estimated Store Visits',
    uniqueclicks: 'Total Unique Clicks',
    usercount: 'Unique Web Visits',
    cvr: 'CVR %',
    zip: 'Zip/Postal Code',
    zipcode: 'Zip Code',
    videocontent: 'Video Content',
    appname: 'App Name',
  };

  if (typeof h === 'string' && h.length > 0) {
    let tmp = h.toLowerCase();
    tmp = tmp.replace(/\s/gi, '');
    return map[tmp] || h;
  }
  return h;
};

// has unit test
const friendlyContactType = (type: ContactsKeyType, agencyId?: string): string => {
  if (!type) return '';
  switch (type) {
    case ContactsKeyType.Decision_maker:
      return 'Customer Decision Maker';

    case ContactsKeyType.Technical:
      return 'Customer Technical Contact';

    case ContactsKeyType.Media_company:
      if (!agencyId) return 'Media Company Contact';
      return `${agencyId} Media Company Contact`;

    case ContactsKeyType.Marketing:
      return `Customer Marketing Contact`;

    default:
      return type;
  }
};

const copyText = (): void => {
  try {
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(document.getElementById('emailContent'));
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  } catch (exp) {
    throw new Error(exp.message);
  }
};

// has unit test
const sortByMostRecentDate = (items: Array<object>, property: string) => {
  function sortArray(a: object, b: object) {
    const dateB = new Date(b[property]).getTime();
    const dateA = new Date(a[property]).getTime();
    return dateB - dateA;
  }
  return items.sort(sortArray);
};

// has unit test
const sortByProperty = (items: Array<object>, property: string) => {
  return items.sort(function (a, b) {
    return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1;
  });
};

const sortNumbersByProperty = (items: Array<object>, property: string) => {
  return items.sort(function (a, b) {
    return a[property] > b[property] ? 1 : -1;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customSort = (items: any[], index: string[], isDesc: boolean[]): any[] => {
  if (!index) {
    return items;
  }
  if (!Array.isArray(index)) {
    index = [index];
  }
  if (!Array.isArray(isDesc)) {
    isDesc = [isDesc];
  }
  const daySorter = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };
  const daypartSorter = {
    morning: 0,
    midday: 1,
    afternoon: 2,
    night: 3,
    overnight: 4,
  };
  const daypartSimplifiSorter = {
    '12am to 4am': 1,
    '4am to 8am': 2,
    '8am to 12pm': 3,
    '12pm to 4pm': 4,
    '4pm to 8pm': 5,
    '8pm to 12am': 6,
  };
  const shortMonthSorter = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };
  // console.log('custom sort', items, index);
  items.sort((a, b) => {
    /* Ugly hack around sorting "other" and "unknown" values to the bottom of the list regardless of their values */

    // specific properties that need to be considered when sorting. We don't want to look at the whole object for performance reasons
    const sortToBottomProperties = [
      'Publisher',
      'CityId',
      'City',
      'Metro',
      'SearchKey',
      'Device',
      'Daypart',
      'GeoFenceName',
      'ZipCode',
      'AddressName',
      'zipcode',
      'County',
      'DMA',
    ];

    // get list of possible properties to sort to bottom
    const matchingProperties = sortToBottomProperties.filter(prop => a.hasOwnProperty(prop));
    if (matchingProperties) {
      for (const prop of matchingProperties) {
        const current = a[prop]?.toLowerCase().trim();
        const next = b[prop]?.toLowerCase().trim();

        if (current?.startsWith('other') || current?.startsWith('unknown')) {
          if (next?.startsWith('other') || next?.startsWith('unknown')) {
            return isDesc ? b[index[0]] - a[index[0]] : a[index[0]] - b[index[0]];
          }
          // if current item should be sorted to the bottom, return 1
          return 1;
        }
        if (next?.startsWith('other') || next?.startsWith('unknown')) {
          // if previous item should be sorted to the bottom, return -1
          return -1;
        }
      }
    }
    /* end ugly hack */
    if (
      [
        'SpendVal',
        'ImpressionPercent',
        'ConversionRate',
        'ClickThrough',
        'Pacing',
        'CostPerClick',
        'CostPerCreative',
        'ImpressionShare',
        'SearchLostISRank',
        'SearchLostISBudget',
        'ResponseNumber',
        'ResponseRate',
      ].includes(index[0])
    ) {
      let strA = a[index[0]];
      let strB = b[index[0]];
      if (index[0] === 'CostPerClick' || index[0] === 'CostPerCreative') {
        strA = strA.substr(1);
        strB = strB.substr(1);
      } else if (index[0] === 'SpendVal') {
        // remove dollar sign, commas
        strA = strA.replace(/[\$\,]/gi, '');
        strB = strB.replace(/[\$\,]/gi, '');
      }
      const valA = parseFloat(strA);
      const valB = parseFloat(strB);
      if (!isDesc[0]) {
        return valA < valB ? -1 : 1;
      } else {
        return valB < valA ? -1 : 1;
      }
    } else if (['EndDate', 'StartDate', 'Date'].includes(index[0])) {
      let strA = a[index[0]];
      let strB = b[index[0]];
      // for now, only extract the day/month/year, and do a string compare, to save the full date parsing cost
      // 10/25/2020 12:00:00 AM -> 2020 10 25
      let lA = strA.split(' ');
      let lB = strB.split(' ');
      if (lA.length === 3 && lB.length === 3) {
        lA = lA[0].split('/');
        lB = lB[0].split('/');
        if (lA.length === 3 && lB.length === 3) {
          strA = `${lA[2]} ${lA[0].length === 1 ? '0' : ''}${lA[0]} ${lA[1].length === 1 ? '0' : ''}${lA[1]}`;
          strB = `${lB[2]} ${lB[0].length === 1 ? '0' : ''}${lB[0]} ${lB[1].length === 1 ? '0' : ''}${lB[1]}`;
          if (typeof a.Time === 'string') {
            strA += ' ' + a.Time;
          }
          if (typeof b.Time === 'string') {
            strB += ' ' + b.Time;
          }
        }
      }
      if (!isDesc[0]) {
        return strA < strB ? -1 : 1;
      } else {
        return strB < strA ? -1 : 1;
      }
    } else if (index[0] === 'Day') {
      const day1 = a[index[0]].toLowerCase();
      const day2 = b[index[0]].toLowerCase();
      if (!isDesc[0]) {
        return daySorter[day1] - daySorter[day2];
      } else {
        return daySorter[day2] - daySorter[day1];
      }
      // } else if (index[0] === 'Date') {
      //  02/26/2021 12:00:00.000 AM 02/25/2021 12:00:00.000 AM
      //   const date1 = a[index[0]];
      //   const date2 = b[index[0]];
      //   try {
      //     const a = date1.split(' ');
      //     const b = date2.split(' ');
      //     if (a.length > 0 && b.length > 0) {
      //       const c = a[0].split('/');
      //       const d = a[0].split('/');
      //       if (c.length === 3 && d.length === 3) {
      //         const strA = c[2] + ' ' + c[0] + ' ' + c[1];
      //         const strB = d[2] + ' ' + d[0] + ' ' + d[1];
      //         if (!isDesc[0]) {
      //           return strA < strB ? -1 : 1;
      //         } else {
      //           return strB < strA ? -1 : 1;
      //         }
      //       }
      //     }
      //   } catch {}
      //   if (!isDesc[0]) {
      //     return date1 < date2 ? -1 : 1;
      //   } else {
      //     return date2 < date1 ? -1 : 1;
      //   }
    } else if (index[0] === 'Daypart') {
      let sorter = daypartSorter;
      const day1 = a[index[0]].toLowerCase();
      const day2 = b[index[0]].toLowerCase();
      if (day1.includes('am') || day1.includes('pm')) {
        sorter = daypartSimplifiSorter;
      }
      if (!isDesc[0]) {
        return sorter[day1] - sorter[day2];
      } else {
        return sorter[day2] - sorter[day1];
      }
    } else if (index[0] === 'ShortDate') {
      // Jan 01, 2021  -> 2021 01 01
      const date1 = a[index[0]];
      const date2 = b[index[0]];
      try {
        const a = date1.split(',');
        const b = date2.split(',');
        if (a.length === 2 && b.length === 2) {
          const c = a[0].split(' ');
          const d = a[0].split(' ');
          if (c.length === 2 && d.length === 2) {
            const strA = a[1] + ' ' + shortMonthSorter[c[0]] + ' ' + c[1];
            const strB = b[1] + ' ' + shortMonthSorter[d[0]] + ' ' + d[1];
            if (!isDesc[0]) {
              return strA < strB ? -1 : 1;
            } else {
              return strB < strA ? -1 : 1;
            }
          }
        }
      } catch {}
      if (!isDesc[0]) {
        return date1 < date2 ? -1 : 1;
      } else {
        return date2 < date1 ? -1 : 1;
      }
    } else if (index[0] === 'Hour') {
      const hour1 = reverseFormatHour(a[index[0]].toLowerCase());
      const hour2 = reverseFormatHour(b[index[0]].toLowerCase());
      if (!isDesc[0]) {
        return hour1 < hour2 ? -1 : 1;
      } else {
        return hour2 < hour1 ? -1 : 1;
      }
    } else if (['CompletionRate'].includes(index[0])) {
      const strA = a[index[0]];
      const strB = b[index[0]];
      const numA = parseFloat(strA.replace(/\d+% ?/g, ''));
      const numB = parseFloat(strB.replace(/\d+% ?/g, ''));
      // for VCR the sorting is reversed
      if (!isDesc[0]) {
        return numB < numA ? -1 : 1;
      } else {
        return numA < numB ? -1 : 1;
      }
    } else if (a.DoNotSort) {
      // this flag disables sorting for an item so it's always last
      return 0;
    } else if (!isNaN(a[index[0]])) {
      if (!isDesc[0]) {
        return a[index[0]] - b[index[0]];
      } else {
        return b[index[0]] - a[index[0]];
      }
    } else {
      if (!isDesc[0]) {
        if (index[0] === 'CreativeName') {
          // for sorting names with numbers, passing undefined to the comparer will use the browsers default locale
          return a[index[0]].localeCompare(b[index[0]], undefined, { numeric: true, sensitivity: 'base' });
        }
        // sort null, undefined values to the bottom
        if (!a[index[0]]) return 1;
        if (!b[index[0]]) return -1;
        return a[index[0]] < b[index[0]] ? -1 : 1;
      } else {
        // sort null, undefined values to the bottom
        if (!a[index[0]]) return 1;
        if (!b[index[0]]) return -1;

        let aVal = a[index[0]];
        let bVal = b[index[0]];

        // sometimes coming back as array
        if (Array.isArray(aVal)) {
          aVal = aVal[0];
        }
        if (Array.isArray(bVal)) {
          bVal = bVal[0];
        }

        if (bVal && aVal) {
          return bVal.toLowerCase() < aVal.toLowerCase() ? -1 : 1; // case matters when sorting
        } else {
          return 0;
        }
      }
    }
  });
  return items;
};

// We have a use case where we need to combine multiple "other" or "null" campaigns into a single "other" campaign for display purposes
// this method looks at which fields exist and combines them
// it's a little tricky because some of the metrics are percentages as string
function combineMetrics(
  existingCampaign: Campaign | null | undefined,
  newCampaign: Campaign | null | undefined,
): Campaign {
  // if the existing campaign is null, just return the new campaign
  if (!existingCampaign) return newCampaign;

  if (newCampaign.ClickThrough) {
    // combine string percentages with 3 decimal points
    const combinedValues = (parseFloat(existingCampaign.ClickThrough) + parseFloat(newCampaign.ClickThrough)).toFixed(
      3,
    );
    // a little magic to return a decimal with no trailing zeros
    existingCampaign.ClickThrough = `${parseFloat(combinedValues).toString()}%`;
  }
  if (newCampaign.Clicks) {
    existingCampaign.Clicks = existingCampaign.Clicks + newCampaign.Clicks;
  }
  if (newCampaign.Impressions) {
    existingCampaign.Impressions = existingCampaign.Impressions + newCampaign.Impressions;
  }
  if (newCampaign.ImpressionPercent) {
    // combine string percentages with 3 decimal points
    const combinedValues = (
      parseFloat(existingCampaign.ImpressionPercent) + parseFloat(newCampaign.ImpressionPercent)
    ).toFixed(3);
    // a little magic to return a decimal with no trailing zeros
    existingCampaign.ImpressionPercent = `${parseFloat(combinedValues).toString()}%`;
  }
  return existingCampaign;
}

function getMapId(mapString: string): string {
  switch (mapString.toLowerCase()) {
    case 'standard-light':
    case 'standardlight':
      return '24753aa3-7a2d-4bb6-9370-e7d657b08efb';
    case 'standard-dark':
    case 'standarddark':
      return '107c3c36-beba-455a-9011-2981de302f85';
    case 'drive-auto':
    case 'driveauto':
      return 'b8112eee-e93d-4851-a112-213795b5415f';
    case 'compulse':
      return '02d26add-49b9-4dab-abaa-afd31603dbc5';
    case 'my-analytics-hub':
    case 'myanalyticshub':
      return '911c0f76-c7cc-45f2-8f9a-105e70f44d5e';
    case 'sbg-analytics':
    case 'sbganalytics':
      return '5f7337d4-e330-4e9a-997d-d17811d4fa41';
    case 'lpp':
      return 'fca25dba-59b6-470c-981f-9607dac9132d';
    case 'dealer-alchemist':
    case 'dealeralchemist':
      return '7dbcf7b9-f3a7-4cff-a0ad-9870fa148aa7';
    case 'ar-marketing':
    case 'armarketing':
      return 'd0e9cd96-e05a-4f5f-9c25-849742d823f6';
    case 'clik-data':
    case 'clikdata':
      return 'fa43e1b7-6cad-4359-a5b4-3ac52a9e3aa6';
    case 'satellite':
      return '381cd47b-1090-4580-b7bb-7058f89205f5';
    case 'printing':
    default:
      return '24753aa3-7a2d-4bb6-9370-e7d657b08efb';
  }
}

const htmlEncode = (input: string): string => {
  if (!input) {
    return '';
  }
  return input.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
    return '&#' + i.charCodeAt(0) + ';';
  });
};

const renderTemplate = function (templateString, templateVars) {
  try {
    if (!templateString) {
      return null;
    }
    const tmpl = templateString.replace(/(\$\{)/gi, '${this.').replace(/(this\.this)/gi, 'this');
    return new Function('return `' + tmpl + '`;').call(templateVars);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('renderTemplate', err, templateString, templateVars);
    return null;
  }
};

const googleSEMFormattedCustomerId = function (id: string) {
  return id ? id.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, '$1-$2-$3') : null;
};

const hasRight = function (customer: {}, acceptedRoles: Array<string>, skipSUCheck = false) {
  if (!customer || !acceptedRoles) return false;

  return roles.HasAccess(customer, acceptedRoles);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const allCampaignDetailsById = function (component: any): object {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hash: any = {};

  // console.log('RETURNING CAMPAIGNLIST from allTimeCampaigns');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component.$store.state.customer?.allTimeCampaigns?.campaignlist?.forEach((c: any) => {
    hash[c.id] = c;
  });
  return hash;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const campaignDetailsById = function (component: any): object {
  // todo: the hash set should be cached, it's computed on every call

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hash: any = {};
  if (component.isExporting) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component.exportData.adData?.CampaignList?.forEach((c: any) => {
      hash[c.CampaignId] = c;
    });
  } else if (component.$store.state.customer?.adPerformance?.CampaignList) {
    // console.log('RETURNING CAMPAIGNLIST from ADPERFORMANCE');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component.$store.state.customer.adPerformance.CampaignList.forEach((c: any) => {
      hash[c.CampaignId] = c;
    });

    if (component.selectedCampaigns && !hash[component.selectedCampaigns[0]?.id]) {
      component.$store.state.customer?.campaignAdPerformance?.CampaignList?.forEach((c: any) => {
        hash[c.CampaignId] = c;
      });
    }
  } else {
    // console.log('RETURNING CAMPAIGNLIST from CAMPAIGNADPERFORMANCE');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component.$store.state.customer?.campaignAdPerformance?.CampaignList?.forEach((c: any) => {
      hash[c.CampaignId] = c;
    });
  }
  return hash;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSummmaryDetailsByType = function (component: any, type: string): any {
  if (!type) {
    return null;
  }
  // todo: the hash set should be cached, it's computed on every call
  // can't rely on state to be populated when exporting
  return adDataForKey(component, `${type.toUpperCase()}Total`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCampaignDateRangeByType = function (component: any, type?: string): Array<string> {
  if (component.isExporting) {
    const analyticsType =
      component.componentConfig.AnalyticsType ||
      component.sectionConfig.AnalyticsType ||
      component.exportData.layout.AnalyticsType;
    if (!analyticsType) {
      return ['', ''];
    }
    const totals = component.exportData.adData[`${analyticsType}Total`];
    if (!totals) {
      return ['', ''];
    }
    if (totals.ContractEndDate) {
      return [totals.ContractStartDate, totals.ContractEndDate];
    } else {
      return [totals.ContractStartDate, 'noEndDate'];
    }
  }
  const types = component.$store.getters.currentTabCampaignTypes;
  if (!types) return ['', ''];
  const campaignType = types[0]; // should only ever be 1 item
  const totals = component.$store.state.customer.campaignAdPerformance[`${campaignType}Total`];

  if (totals) {
    if (type === 'datePicker') {
      let start;
      let end;
      // DASH-4573 - get the earliest possible start date
      const dateRangeStart = totals?.DaterangeStart || totals?.StartDate;
      const dateRangeStartNormalize = normalizeDate(dateRangeStart);
      const startDatePerformanceNormalize = normalizeDate(totals?.StartDatePerformance);
      if (!startDatePerformanceNormalize) {
        start = dateRangeStart;
      } else {
        start = dateRangeStartNormalize > startDatePerformanceNormalize ? totals?.StartDatePerformance : dateRangeStart;
      }

      // DASH-4573 - get the latest possible end date
      const dateRangeEnd = totals?.DaterangeEnd || totals?.EndDate;
      const dateRangeEndNormalize = normalizeDate(dateRangeEnd);
      const endDatePerformanceNormalize = normalizeDate(totals?.EndDatePerformance);
      if (!endDatePerformanceNormalize) {
        end = dateRangeEnd;
      } else {
        end = dateRangeEndNormalize > endDatePerformanceNormalize ? dateRangeEnd : totals?.EndDatePerformance;
      }

      return [start, end];
    } else if (totals.ContractEndDate) {
      return [totals.ContractStartDate, totals.ContractEndDate];
    } else {
      return [totals.ContractStartDate, 'noEndDate'];
    }
  }

  // if we can't get the start and end date from the performance data, try loading it from the campaign data
  if (component.$store.state.customer?.campaigns?.startDate && component.$store.state.customer?.campaigns?.endDate)
    return [component.$store.state.customer?.campaigns?.startDate, component.$store.state.customer?.campaigns?.endDate];

  return ['', '']; // TODO: find some default
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLastModifiedDate = function (component: any): Date | string {
  let date: Date | number;
  if (component.isExporting) {
    let source = component.exportData.adData;
    if (component.componentConfig?.useTacticSummaryData) {
      source = component.exportData.tacticsSummary;
    }
    if (source && source.LastModifiedDate) {
      date = this.normalizeDate(component.exportData.adData.LastModifiedDate);
      return format(date, 'M/dd/yy');
    }
  }

  let source = component.$store.state.customer.campaignAdPerformance;
  if (component.componentConfig?.useTacticSummaryData) {
    source = component.$store.state.customer.tacticSummaryPerformance;
  }

  if (!source?.LastModifiedDate) {
    return '';
  }
  // prevent customRange dates from affecting LastModifiedDate
  if (
    component.$store.state.customer.staticLastModifiedDate &&
    component.$store.state.customer.staticLastModifiedDate !== ''
  ) {
    source.LastModifiedDate = component.$store.state.customer.staticLastModifiedDate;
  }

  try {
    date = this.normalizeDate(source.LastModifiedDate);
    return format(date, 'M/dd/yy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('lastModifiedDate', date, err);
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCampaignDatePickerRange = function (component: any): Array<string> {
  // almost identical to getCampaignDateRange but it looks for a different date
  return getCampaignDateRangeByType(component, 'datePicker');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCampaignDateRange = function (component: any): Array<string> {
  return getCampaignDateRangeByType(component);
};

// normalized phone numbers | has unit test
function formatPhoneNumber(phoneNumber: string) {
  let phone = phoneNumber.replace('+1', ''); // remove leading +1
  phone = phone.replace(/[^\d]/g, ''); // remove non-numeric characters
  const r = /([0-9]{3})([0-9]{3})([0-9]{4})/;
  phone = phone.replace(r, '($1) $2-$3');
  if (phone.length === 14) {
    return phone;
  }
  // if something went wrong and we failed to get a valid phone number, return the original
  return phoneNumber;
}

// has unit test
function normalizeDate(date: string): Date {
  if (!date) return;
  const formats = {
    monthDayYear: /[0-9]{2}-[0-9]{2}-[0-9]{4}/,
    yearMonthDay: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
    friendlyMonthDayYear: /[a-z]{3} [0-9]{2}, [0-9]{4}/i, // "Feb 05, 2022"
    iso8601: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/,
  };
  if (formats.iso8601.test(date)) return parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date());
  if (formats.yearMonthDay.test(date)) return parse(date, 'yyyy-MM-dd', new Date());
  if (formats.monthDayYear.test(date)) return parse(date, 'MM-dd-yyyy', new Date());
  if (formats.friendlyMonthDayYear.test(date)) return parse(date, 'MMMM dd, yyyy', new Date());
  else return parse(date, 'MM/dd/yyyy hh:mm:ss.SSS a', new Date());
}

const tryParseShortDate = (date: string): Date | null => {
  if (!date) {
    return null;
  }
  try {
    // for now, only parse date, ignores time
    date = date.split(' ')[0];

    // saves some processing, don't forget to remove if other patterns
    if (date.length !== 10) {
      return null;
    }
    const formats = {
      monthDashDayDashYear: /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/,
      yearDashMonthDashDay: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
      monthSlashDaySlashYear: /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
      yearSlashMonthSlashDay: /^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/,
    };
    // todo: try the most common formats first
    if (formats.yearDashMonthDashDay.test(date)) {
      return parse(date, 'yyyy-MM-dd', new Date());
    }
    if (formats.monthDashDayDashYear.test(date)) {
      return parse(date, 'MM-dd-yyyy', new Date());
    }
    if (formats.yearSlashMonthSlashDay.test(date)) {
      return parse(date, 'yyyy/MM/dd', new Date());
    }
    if (formats.monthSlashDaySlashYear.test(date)) {
      return parse(date, 'MM/dd/yyyy', new Date());
    }
  } catch (exp) {
    // eslint-disable-next-line no-console
    console.log('tryParseShortDate', date, exp);
  }
  return null;
};

const hasCampaignEnded = (campaignEndDate: string): boolean => {
  const currentDate = new Date();
  return Date.parse(currentDate.toString()) > Date.parse(campaignEndDate);
};

// has unit test
const hasAllProperties = (obj: object, properties: string[]): boolean => {
  const hasAll = properties.reduce((all: boolean, property: string) => {
    return obj.hasOwnProperty(property) && all;
  }, true);
  return hasAll;
};

// has unit test
const slugify = (str: string): string => {
  return str
    ? str
        .toLowerCase()
        .replace(/[^A-Za-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
    : '';
};
// used for filename for data from exports
const tacticTitleMap = (reportName: string): string => {
  const map = {
    OTT: 'CTV',
    SIMPOTT: 'OTT',
    PREROLL: 'Video',
    SIMPPREROLL: 'Preroll',
    DISPLAY: 'Display',
    SIMPDISPLAY: 'Display',
    SOCIAL: 'Facebook Ads',
    SEM: 'SEM',
    SIMPGEOFENCE: 'Geofence',
    BROADCAST: 'TV',
    BROADSTREET: 'Broadstreet - O&O',
    GTDISPLAY: 'Geofence - Display',
    GTVIDEO: 'Geofence - Video',
    GA: 'Google Analytics',
    CALLTRACKING: 'Call Tracking',
    GOOGLESEARCH: 'Google Search Console',
    FACEBOOK: 'Facebook Ads',
    GAMDISPLAY: 'Display - O&O',
    GAMVIDEO: 'Video - O&O',
    EMAILSI: 'Email Marketing',
    VIDEO: 'Digital Video (Video + OTT)',
    GOOGLEVIDEO: 'YouTube',
    FBINSIGHTS: 'Facebook Organic',
    TV2OTT: 'InnovidXP',
    AUDIO: 'Audio',
    SITEIMPACT: 'Email Marketing',
    TRUGEOFENCE: 'True Geo',
    LINEAR: 'Sinclair RSN',
  };
  return map[reportName?.toUpperCase()] || reportName;
};

const dataKeyBySectionIdMap = (id: string): string => {
  if (id === undefined) return;
  id = id.toLowerCase();
  const map = {
    digitalvideo: 'VIDEO',
    digitalvideovideoott: 'VIDEO',
    display: 'DISPLAY',
    gtdisplay: 'GTDISPLAY',
    gtvideo: 'GTVIDEO',
    ott: 'OTT',
    preroll: 'PREROLL',
    simpgeofence: 'SIMPGEOFENCE',
    trugeofence: 'TRUGEOFENCE',
    broadcast: 'BROADCAST',
    broadstreet: 'BROADSTREET',
    calltracking: 'CALLTRACKING',
    ga: 'GA',
    gamdisplay: 'GAMDISPLAY',
    gamvideo: 'GAMVIDEO',
    googlesearch: 'GOOGLESEARCH',
    linear: 'LINEAR',
    emailsi: 'SITEIMPACT',
    social: 'SOCIAL',
    sem: 'SEM',
    seo: 'SEO',
    fbinsights: 'FBINSIGHTS',
    googlevideo: 'GOOGLEVIDEO',
    tv2ott: 'TV2OTT',
  };
  return map[id] || id.toUpperCase();
};

const tabIdNameMap = (tab: string) => {
  const t = tab.toLowerCase().replace(/\s/g, '');
  if (tacticObject[t]) {
    return tacticObject[t];
  }

  const map = {
    summary: 'Summary',
    preroll: 'Video',
    ott: 'CTV',
    video: 'Digital Video (Video + OTT)',
    display: 'Display',
    linear: 'Sinclair RSN',
    sem: 'SEM',
    social: 'Facebook Ads',
    broadcast: 'TV',
    broadstreet: 'Broadstreet - O&O',
    simpgeofence: 'Geofence',
    geofence: 'Geofence',
    googlesearch: 'Google Search Console',
    ga: 'Google Analytics',
    gam: 'Google Ad Manager',
    gamvideo: 'Video - O&O',
    gamdisplay: 'Display - O&O',
    calltracking: 'Call Tracking',
    emailsi: 'Email Marketing',
    emailmarketing: 'Email Marketing',
    fbinsights: 'Facebook Organic',
    googlevideo: 'YouTube',
    tv2ott: 'InnovidXP',
    audio: 'Audio',
    gtdisplay: 'Geofence - Display',
    gtvideo: 'Geofence - Video',
  };
  return map[tab] || tab;
};

const tableMetricIntegerValues = [
  'Impressions',
  'impressions',
  'Conversions',
  'Aired',
  'Visits',
  'Views',
  'Clicks',
  'ClickThrough',
  'GAUsers',
  'Sessions',
  'PageViews',
  'BounceRate',
  'HHImpressions',
  'A18TO49Impressions',
  'A25TO54Impressions',
  'VC100',
  'vc100',
  'hhImpressions',
  'hhimpressions',
];

const phoneMetrics = ['TrackingNumber']; // list of metrics that are phone numbers

// has unit test
const filterOutColumns = (columns: string[], filterOut: string[]): string[] => {
  const filtered = columns.filter((col: string) => !filterOut.includes(col));
  return filtered;
};

// has unit test
const removeWhiteSpaceLowerCase = (item: string): string => {
  if (!item) return null;
  // remove white space, forward slashes, &, +, then lower case
  const transformItem = item.replace(/[\s\/&+()]+/g, '').toLowerCase();
  return transformItem;
};

// has unit test
const removeNullFromObjectOrArray = (obj: object | object[]) => {
  if (!obj) return null;
  try {
    if (Array.isArray(obj)) {
      return obj
        .map(value => (value && typeof value === 'object' ? removeNullFromObjectOrArray(value) : value))
        .filter(value => !(value == null));
    } else {
      return Object.entries(obj)
        .map(([key, value]) => [key, value && typeof value === 'object' ? removeNullFromObjectOrArray(value) : value])
        .reduce((acc, [key, value]) => {
          return key === '__typename' || value == null ? acc : ((acc[key] = value), acc);
        }, {});
    }
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.info('Error in removeNullFromObjectOrArray', err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const allowSingleCampaignView = (component: any, isExport?: boolean): boolean => {
  let currentSectionId, sectionData;
  if (isExport) {
    currentSectionId = dataKeyBySectionIdMap(component.tab);
    sectionData = propertyByPath(component.adData, currentSectionId);
  } else {
    // currentSectionId = dataKeyBySectionIdMap(component.$store.state.customer.currentSection.id).toLowerCase();

    if (component.$store.state.customer.sharedDashboard) {
      // when shared, we have to get the mapped tactic id. This currently only matters for site impact
      const currentSectionId = dataKeyBySectionIdMap(component.$store.state.customer.currentSection.id).toLowerCase();
      sectionData = component.$store.state.customer.adPerformance[currentSectionId.toUpperCase()];
    } else {
      const currentSectionId = component.$store.state.customer.currentSection?.id?.toLowerCase();
      sectionData = component.$store.state.customer?.currentDashboard?.products.find(p => p.id === currentSectionId);
    }
  }
  return sectionData?.AllowSingleCampaignView;
};

const sortByAscMetrics = ['Metro', 'CompletionRate', 'ClickThrough', 'VCR', 'CreativeId', 'CreativeName'];

const percentageMetrics = ['ClickThrough', 'ImpressionPercent', 'VCR', 'CompletionRate'];

const getLayoutComponents = (context, type?: string) => {
  let nonSummaryComps = null;
  if (type === 'default') {
    nonSummaryComps = context.$store.state.customer?.defaultLayout?.layoutCustomizations?.config.components.find(
      c => c.type !== 'summaryonly',
    );
  } else {
    nonSummaryComps = context.$store.state.customer?.dynamicLayout?.layoutCustomizations?.config.components.find(
      c => c.type !== 'summaryonly',
    );
  }
  return nonSummaryComps?.components;
};

const breakpointOptions = [
  {
    text: 'Half',
    value: ['lg6', 'sm12'],
    icon: 'compress',
  },
  {
    text: 'Full',
    value: ['lg12', 'sm12'],
    icon: 'expand',
  },
];

const mapDataOptions = [
  {
    text: 'Geofencing Cities',
    value: 'geofencingCities',
    icon: 'compress',
  },
  {
    text: 'Geofencing Locations',
    value: 'geofencingLocations',
    icon: 'expand',
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const feedSources = (component: any): Array<string> => {
  let selectedCampaigns;
  if (component.isExporting) {
    selectedCampaigns = component.exportData?.adData?.CampaignList;
  } else {
    selectedCampaigns = component.$store.state.filters.selectedCampaigns;
  }

  if (!Array.isArray(selectedCampaigns)) {
    // // eslint-disable-next-line no-console
    // console.log(`feedSources: no selectedCampaigns in ${component.$vnode?.componentOptions?.tag}`);
    return [];
  }

  const feedSources: Set<string> = new Set();
  selectedCampaigns.reduce((sources, campaign: CampaignDetails) => {
    if (campaign.FeedSource) {
      if (Array.isArray(campaign.FeedSource)) {
        for (const s of campaign.FeedSource) {
          feedSources.add(s);
        }
      } else if (typeof campaign.FeedSource === 'string' && campaign.FeedSource.length) {
        feedSources.add(campaign.FeedSource);
      }
    }
    return sources;
  }, feedSources);

  return Array.from(feedSources);
};

// Campaign summary metric filtering for module and configurabilty options
interface Metric {
  title: string;
  key: string;
}

const dataForSummaryMetrics = component => {
  let selectedCampaigns, campaignDetails, campaignType;
  if (component.isExporting) {
    selectedCampaigns = component.exportData.adData?.CampaignList;
    if (Array.isArray(selectedCampaigns) && selectedCampaigns.length > 0) {
      campaignType = selectedCampaigns[0].AnalyticsType;
    }
  } else {
    selectedCampaigns = component.$store.state.filters.selectedCampaigns;
    if (Array.isArray(selectedCampaigns) && selectedCampaigns.length > 0) {
      campaignType = selectedCampaigns[0].CampaignType;
    }
  }

  if (component.componentConfig?.useTacticSummaryData) {
    if (component.isExporting) {
      campaignDetails = component.exportData.tacticsSummary['GOOGLEVIDEOTotal'];
    } else {
      campaignDetails = component.$store.state.customer.tacticSummaryPerformance['GOOGLEVIDEOTotal'];
    }
  } else {
    if (!Array.isArray(selectedCampaigns) || selectedCampaigns.length === 0 || !campaignType) {
      // eslint-disable-next-line no-console
      console.log(`filteredMetrics: no selectedCampaigns`);
      return null;
    }
    campaignDetails = getSummmaryDetailsByType(component, campaignType);
  }

  if (!campaignDetails) {
    // eslint-disable-next-line no-console
    console.log(`filteredMetrics: no campaignDetails`);
    return null;
  }
  return { selectedCampaigns, campaignDetails, campaignType };
};

// has unit test
const filteredMetrics = (component, metrics: Array<String>): Array<Metric> => {
  let filtered: Array<Metric> = [];
  const data = dataForSummaryMetrics(component);
  const spendMetricKeys = [
    'SpendVal',
    'CostPerClick',
    'InnovidXPCostPerResponse',
    'CostPerMille',
    'CostPerView',
    'CostPerDeployment',
  ];
  const feedNames = feedSources(component);

  if (!data) {
    return [];
  }

  // metric naming set up
  filtered = metrics.map((metric: string) => {
    let metricTitle = headerNamesMap(metric);
    const isSocial = component.isExporting
      ? component.componentConfig.tacticName === 'Social'
      : component.$store.state.customer.currentNavTab === 'social';
    // change Conversions title name for Facebook tab
    if (metric === 'Conversions' && isSocial) {
      metricTitle = 'Link Clicks';
    }

    // change some metrics for TV2OTT
    const isTvsquared = component.isExporting
      ? component.componentConfig.tacticName === 'tv2ott'
      : component.$store.state.customer.currentNavTab === 'tv2ott';
    if (metric === 'Impressions' && isTvsquared) {
      metricTitle = 'Household Impressions';
    }
    if (metric === 'SpendVal' && isTvsquared) {
      metricTitle = 'Total Media Spend';
    }
    return {
      title: metricTitle,
      key: metric,
    };
  });

  filtered = filtered.filter((metric: Metric) => {
    let show = true;
    // Hide zeros
    if (component?.componentConfig?.campaignSummary?.hideZeros) {
      const noSpecChars = data.campaignDetails[metric.key]?.toString().replace(/[^a-zA-Z0-9 ]/g, '');
      show = parseFloat(noSpecChars) > 0;
    }
    // Pacing rules
    if (metric.key === 'Pacing') {
      if (!data.campaignDetails?.ImpressionGoal) {
        // hide pacing if there is no ImpressionGoal
        show = false;
      }
    }
    // Reach/Frequency rules
    if (metric.key === 'Reach' || metric.key === 'Frequency') {
      // hide if 0, undefined/null, (Amazon), or  multiple campaigns selected
      if (
        !data.campaignDetails[metric.key] ||
        data.campaignDetails[metric.key] < 1 ||
        feedNames.includes('AMAZON')
        // data.selectedCampaigns?.length > 1
      )
        return false;
    }
    // VCR/VC100 rules
    if (metric.key === 'VCR' || metric.key === 'VC100') {
      // hide if 0, undefined/null
      const noSpecChars = parseFloat(data.campaignDetails?.[metric.key]?.toString().replace(/[^a-zA-Z0-9 ]/g, ''));
      if (!noSpecChars) return false;
    }
    // Spend rules
    if (spendMetricKeys.includes(metric.key)) {
      // HideSpend flag check
      if (data.campaignDetails?.HideSpend && !component.componentConfig?.useTacticSummaryData) {
        show = false;
      }
      // hide cpc/spend value if campaign spending not set or disabled.
      if (!data.campaignDetails['SpendVal'] || data.campaignDetails['SpendVal'] === 'NA') {
        show = false;
      }

      // hide CPR if OTT without InnovidXP data
      if (
        metric.key === 'InnovidXPCostPerResponse' &&
        data.campaignType === 'OTT' &&
        !data.campaignDetails[metric.key]
      ) {
        show = false;
      }
    }

    const innovidXPMetrics = [
      'InnovidXPEstimatedSearchImpressions',
      'InnovidXPImpressionShare',
      'InnovidXPImpressions',
      'InnovidXPMarkupRate',
    ];
    const isOTT = component.isExporting
      ? component.componentConfig.tacticName === 'OTT'
      : component.$store.state.customer.currentNavTab === 'ott';
    if (
      isOTT &&
      innovidXPMetrics.includes(metric.key) &&
      data.campaignDetails['InnovidXPEstimatedSearchImpressions'] === undefined
    ) {
      show = false;
    }

    return show;
  });
  return filtered;
};

const saveRoutes = (to, title: string, key: string): void => {
  const recentStr: string | null = localStorage.getItem(key);
  if (recentStr) {
    try {
      let recent: Array<SavedRoute> = JSON.parse(recentStr);
      // if visited before, move to front.
      const match = recent.find((page: SavedRoute) => page.fullPath === to?.fullPath);
      if (match) {
        recent = recent.filter((page: SavedRoute) => page !== match);
        recent.unshift(match);
        localStorage.setItem(key, JSON.stringify(recent));
        return;
      }
      recent = [{ name: to.name, query: to.query, fullPath: to.fullPath, params: to.params, title }, ...recent];
      recent = Array.from(new Set(recent));
      // keep only first 11, one extra for home page
      if (recent.length > 11) {
        recent = recent.slice(0, 11);
      }
      localStorage.setItem(key, JSON.stringify(recent));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('saveRoutes', err);
    }
  } else {
    localStorage.setItem(key, JSON.stringify([{ name: to.name, query: to.query, fullPath: to.fullPath, title }]));
  }
};

// has unit test
const getRoutes = (key: string): SavedRoute => {
  const recentStr: string | null = localStorage.getItem(key);
  const home = { name: 'home', query: { recent: 'true' } };
  if (!recentStr) {
    return home;
  }
  try {
    const recent: Array<SavedRoute> = JSON.parse(recentStr);
    if (recent[0].fullPath) {
      return recent[0];
    }
    return home;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('getRoutes', err);
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeAllTypenames = (item: any) => {
  if (!item) return;

  const cleaned = (source, obj) => {
    if (!source) return;

    if (Array.isArray(source)) {
      for (let i = 0; i < source.length; i++) {
        const item = source[i];
        if (item !== undefined && item !== null) {
          source[i] = cleaned(item, item);
        }
      }
      return obj;
    } else if (typeof source === 'object') {
      for (const key in source) {
        if (key === '__typename') continue;
        const property = source[key];
        if (Array.isArray(property)) {
          obj[key] = cleaned(property, property);
        } else if (!!property && typeof property === 'object') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
          const { __typename, ...rest } = property;
          obj[key] = cleaned(rest, rest);
        } else {
          obj[key] = property;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { __typename, ...rest } = obj;
      return rest;
    } else {
      return obj;
    }
  };
  return cleaned(JSON.parse(JSON.stringify(item)), {});
};

const isAccessTokenExpired = (rawAccessToken: string): boolean => {
  const accessToken: tAHToken = jwt_decode(rawAccessToken);
  const now = new Date().getTime() / 1000;
  const REFRESH_THRESHOLD_IN_SECONDS = 60 * 1;
  // Check if the token is about to expire within REFRESH_THRESHOLD_IN_SECONDS
  return accessToken?.exp - now < REFRESH_THRESHOLD_IN_SECONDS;
};

const updateAccessToken = async () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const mixpanel = require('./plugins/mixpanel').default;
  const AH_PRODUCT_ID = 5;
  const refreshToken = localStorage.getItem('ah:refreshToken');
  const response = await fetch(`${process.env.VUE_APP_AH_API_ROOT}api/auth/token/refresh/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      refresh: refreshToken,
      product_id: AH_PRODUCT_ID,
      agency_id: store.getters.user?.active_agency_id,
      sub_agency_id: store.getters.user?.active_sub_agency_id,
      station_id: store.getters.user?.active_station_id,
    }),
  });
  if (response.ok) {
    mixpanel.track('Access token refreshed');
    const data = await response.json();
    // console.log('interceptors new token after', data);
    const accessToken = data.access;
    localStorage.setItem('ah:accessToken', accessToken);
    store.commit('SET_USER_ACCESS_TOKEN', accessToken); // Dispatch the corresponding Vuex mutation to update the accessToken
    return accessToken;
  } else {
    const statusCode = response.status;
    if ([401, 400].includes(statusCode)) {
      window.location.href = '/logout';
      return;
    }
    throw new Error('Failed to refresh token');
  }
};

type MenuItem = {
  id: string;
  title: string;
  icon: string;
  children?: MenuItem[];
};
export interface MenuItemsTypes {
  [key: string]: MenuItem;
}

const MENU_ITEMS: MenuItemsTypes = {
  EDIT_ADVERTISER: {
    id: 'edit_adv',
    title: 'Edit Advertiser',
    icon: '',
  },
  TO_MANAGE_REPORTS: {
    id: 'go_to_reports',
    title: 'Manage Reports',
    icon: '',
  },
  ADVERTISER_VIEW: {
    id: 'view_adv',
    title: 'Advertiser View',
    icon: '',
  },
  BACK_TO_DASHBOARD: {
    id: 'back_to_adv',
    title: 'Back to Dashboard',
    icon: 'Arrow_Undo_Down_Left',
  },
  MANAGE: {
    id: 'manage',
    title: 'Manage',
    icon: 'Settings_Future',
    children: [],
  },
  MANAGE_USERS: {
    id: 'manage_users',
    title: 'Users',
    icon: '',
  },
  MANAGE_ADVERTISERS: {
    id: 'manage_adv',
    title: 'Advertisers',
    icon: '',
  },
  MANAGE_AGENCIES: {
    id: 'manage_agnc',
    title: 'Agencies',
    icon: '',
  },
  MANAGE_SYSTEM: {
    id: 'manage_sys',
    title: 'System',
    icon: '',
  },
  FEED_TOOLS: {
    id: 'feed_tools',
    title: 'Feed Tools',
    icon: '',
  },
  SHARE_THIS_PAGE: {
    id: 'share_this_page',
    title: 'Share this',
    icon: 'Share_Android',
    children: [],
  },
  SHARE_PAGE_WITH_DATES: {
    id: 'share_page_with_dates',
    title: 'With editable date range',
    icon: '',
  },
  SHARE_PAGE_WITHOUT_DATES: {
    id: 'share_page_without_dates',
    title: 'Without editable date range',
    icon: '',
  },
  CAMPAIGN_OPTIONS: {
    id: 'campaign_options',
    title: 'Campaign Options',
    icon: 'Chart_Bar_Vertical_01',
    children: [],
  },
  ADVERTISER_OPTIONS: {
    id: 'advertiser_options',
    title: 'Advertiser Options',
    icon: 'Edit_Pencil_Line_01',
    children: [],
  },
  GO_FULLSCREEN: {
    id: 'fullscr',
    title: 'Go fullscreen',
    icon: 'Expand',
  },
  EXIT_FULLSCREEN: {
    id: 'exit_fullscr',
    title: 'Exit fullscreen',
    icon: 'Shrink',
  },
  SUPPORT_CENTER: {
    id: 'support',
    title: 'Help',
    icon: 'Circle_Warning',
  },
  LOGOUT: {
    id: 'logout',
    title: 'Sign out',
    icon: 'Log_Out',
  },
  THEME_EDITOR: {
    id: 'edit_theme',
    title: 'Theme editor',
    icon: 'Swatches_Palette',
  },
  CUSTOMIZE: {
    id: 'custom',
    title: 'Customize',
    icon: '',
  },
  SHOW_ALL_TACTICS: {
    id: 'show_all_tactics',
    title: 'Show all products',
    icon: '',
  },
  SHOW_TACTICS_WITH_CAMPAIGNS: {
    id: 'show_tactics_with_campaigns',
    title: 'Show only products with campaigns',
    icon: '',
  },
  EDIT_CAMPAIGN_PERMISSIONS: {
    id: 'edit_campaign_permissions',
    title: 'Edit campaign permissions',
    icon: '',
  },
  CAMPAIGN_NOTES: {
    id: 'campaign_notes',
    title: 'Notes',
    icon: '',
  },
  CAMPAIGN_SPENDING: {
    id: 'campaign_spending',
    title: 'Spend and Markup',
    icon: '',
  },
  ARCHIVE: {
    id: 'archive',
    title: 'Archive',
    icon: 'Archive',
    children: [
      {
        id: 'archive_campaign',
        title: 'Archive this campaign',
        icon: '',
      },
      {
        id: 'archive_product',
        title: 'Archive this tactic',
        icon: '',
      },
      {
        id: 'archive_year',
        title: 'Archive this year',
        icon: '',
      },
    ],
  },
  CAMPAIGN_ARCHIVE_RESTORE: {
    id: 'campaign_archive_restore',
    title: 'Restore',
    icon: 'Archive archive-icon-restore',
  },
};

const SIDEBAR_ICONS = [
  {
    id: 'Summary',
    icon: 'Chart_pie',
  },
  {
    id: 'Programmatic',
    icon: 'Main_Component',
  },
  {
    id: 'Paid Search',
    icon: 'Note_Search',
  },
  {
    id: 'Attribution',
    icon: 'Option',
  },
  {
    id: 'Social',
    icon: 'Chat_Conversation',
  },
  {
    id: 'E-mail',
    icon: 'Mail_Open',
  },
  {
    id: 'Broadcast',
    icon: 'Monitor_Play',
  },
  {
    id: '',
    icon: '"Settings"',
  },
];

const urlsToRedirect = [
  'analytics.adportal.io',
  'analytics.stg.adportal.io',
  'analytics.dev.adportal.io',
  'analytics.analytics.stg.adportal.io',
  'analytics.analytics.dev.adportal.io',
  'mediaplanner.analytics.stg.adportal.io',
  'mediaplanner.analytics.dev.adportal.io',
  'admin.analytics.stg.adportal.io',
  'admin.analytics.dev.adportal.io',
];

const compulseRedirectURL = 'https://compulse360.analytics.adportal.io/';

const youtubeAgeMapping = {
  AGE_RANGE_18_24: '18-24',
  AGE_RANGE_25_34: '25-34',
  AGE_RANGE_35_44: '35-44',
  AGE_RANGE_45_54: '45-54',
  AGE_RANGE_55_64: '55-64',
  AGE_RANGE_65_UP: '65+',
  AGE_RANGE_UNDETERMINED: 'Other',
};

const formatDate = inputDate => {
  if (!inputDate) return '';

  const date = new Date(inputDate);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return '';
  }

  // Extract the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Format the date in YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

const campaignStatusMapping = [
  {
    code: 'All',
  },
  {
    code: 'Live',
    textToDisplay: 'LIVE',
    color: '#3FBC56',
    icon: 'Play_Circle',
    isPartial: false,
    tooltipText: 'The campaign is active - the start date is in the past and the end date is in the future',
  },
  {
    code: 'Cancelled',
    textToDisplay: 'CANCELLED',
    color: '#E12719',
    icon: 'Close_Circle',
    isPartial: false,
    tooltipText: 'The campaign is now inactive and is no longer serving impressions',
  },
  {
    code: 'Partially Reserved',
    textToDisplay: 'PARTIALLY_RESERVED',
    color: '#FF8927',
    icon: 'Check',
    isPartial: true,
    tooltipText:
      'At least one line item within the campaign is active and inventory has been reserved in the ad server',
  },
  {
    code: 'Reserved',
    textToDisplay: 'RESERVED',
    color: '#FF8927',
    icon: 'Check',
    isPartial: false,
    tooltipText:
      'The campaign has been sent to the ad server and all line items within the campaign have inventory already reserved',
  },
  {
    code: 'Completed',
    textToDisplay: 'COMPLETED',
    color: '#1E5AFF',
    icon: 'Checkbox_Check',
    isPartial: false,
    tooltipText: 'The campaign has completed its flight and is no longer serving impressions',
  },
  {
    code: 'Proposal',
    textToDisplay: 'PROPOSAL',
    color: '#FF8927',
    icon: 'File_Document',
    isPartial: false,
    tooltipText: 'The campaign has not yet been approved and ordered. It is currently not active.',
  },
  {
    code: 'Paused',
    textToDisplay: 'PAUSED',
    color: '#3D3D3D',
    icon: 'Pause_Circle',
    isPartial: false,
    tooltipText: 'The campaign was previously active, but is now temporarily paused and is not delivering impressions.',
  },
];
/**
 * 1. if this is shared view, take what was passed in the token
 *    if there is no agencyThemeCode in the token (old shared link), use old logic:
 *       - if Theme starts from uppercase - means that's VERY old shared link but we still support them
 *       - in that case we don't have agency of campaign, so we'll use agency from url
 * 2. if the user is AH SU, we will use code of advertiser's parent agency, !NB sub-agencies are not considered ATM!
 *    there are 3 places depending on the page - store for view from campaign, data for advertiser view/report creation
 * 3. in other cases, take theme_code from token. it should be the same as the one in the url
 * 4. in case of any error, use default theme - agency
 */
const getPortalTheme = (component: any) => {
  if (component.$store.state.customer?.sharedDashboard) {
    if (component.$store.state.customer?.sharedSelection?.agencyThemeCode)
      return component.$store.state.customer?.sharedSelection?.agencyThemeCode.toLowerCase();
    const hostSegments = window.location.hostname.toLowerCase().split('.');
    const theme = component.$store.state.customer?.selection?.theme || 'agency';
    if (theme[0] === theme[0].toUpperCase()) {
      return hostSegments[0];
    } else {
      return theme;
    }
  }
  if (component.$store.getters?.user?.is_superuser) {
    const isOnCampaign = component.$store.state.advertiser?.advertiserInfo?.data;
    const isOnAdvertiserView = Boolean(component.selection?.advertiserParentAgencyTheme);
    const isOnReport = Boolean(component?._props?.advertiserTheme);
    if (isOnCampaign) return component.$store.state.advertiser?.advertiserInfo?.data?.Theme || 'agency';
    if (isOnAdvertiserView) return component.selection?.advertiserParentAgencyTheme || 'agency';
    if (isOnReport) return component?._props?.advertiserTheme || 'agency';
    return 'agency';
  }
  return component.$store.getters.user?.portalTheme || 'agency';
};

const getExportState = (cid: string) => {
  const exportState = JSON.parse(JSON.stringify(store.getters.exportState || {}));
  if (!exportState[cid]) {
    exportState[cid] = {};
  }
  return exportState;
};

const updateExportState = (componentCid: string, key: string, value: any) => {
  const exportState = getExportState(componentCid);

  if (exportState[componentCid][key] !== value) {
    exportState[componentCid][key] = value;
    store.dispatch('setExportState', exportState);
  }
};

export default {
  adDataForKey,
  addImageProcess,
  allowSingleCampaignView,
  allCampaignDetailsById,
  campaignDetailsById,
  copyText,
  createdModifiedDate,
  customSort,
  combineMetrics,
  dataKeyBySectionIdMap,
  enabledColor,
  enabledIcon,
  enabledText,
  ensureArray,
  filterOutColumns,
  fireOnAdDataChange,
  formatFloatToPercent,
  formatHour,
  formatNumberWithCommas,
  addNumbersWithCommas,
  formatValueEstimate,
  friendlyContactType,
  getCampaignDatePickerRange,
  getCampaignDateRange,
  getDaypartLabel,
  getEnvironment,
  getExportState,
  updateExportState,
  getLastModifiedDate,
  getLogo,
  getMapId,
  getRoutes,
  getSetOfTactics,
  getSummmaryDetailsByType,
  getTacticName,
  getTooltipsFromMetric,
  dataFillTooltips,
  dataFillTooltipsHelper,
  googleSEMFormattedCustomerId,
  hasAllProperties,
  hasRight,
  headerNamesMap,
  hexToRGBA,
  iconLibrary,
  metricIconMap,
  metricIconMapC360,
  isDemoAdvertiser,
  isDevelopment,
  isLocalDev,
  isProduction,
  isSinclair,
  isStaging,
  isWaitingOnData,
  isUnknownOrOtherOrInvalidString,
  normalizeDate,
  formatPhoneNumber,
  phoneMetrics,
  propertyByPath,
  removeWhiteSpaceLowerCase,
  removeNullFromObjectOrArray,
  renderTemplate,
  reverseFormatHour,
  saveRoutes,
  selectedDMAs,
  selectedTypes,
  sinclairSites,
  slugify,
  sortByMostRecentDate,
  sortByProperty,
  sortNumbersByProperty,
  stringFormat,
  tabIdNameMap,
  tacticObject,
  tacticTitleMap,
  toggleFullScreen,
  tryParseShortDate,
  ValidationRules,
  tableMetricIntegerValues,
  sortByAscMetrics,
  percentageMetrics,
  groupTactics,
  getLayoutComponents,
  breakpointOptions,
  mapDataOptions,
  feedSources,
  htmlEncode,
  filteredMetrics,
  removeAllTypenames,
  MENU_ITEMS,
  SIDEBAR_ICONS,
  isFullscreen,
  getEnvForProductLink,
  getEnvForProductLinkOld,
  parsedTacticNames,
  hasCampaignEnded,
  getAgencyFromURL,
  urlsToRedirect,
  compulseRedirectURL,
  isAccessTokenExpired,
  updateAccessToken,
  youtubeAgeMapping,
  formatDate,
  campaignStatusMapping,
  getPortalTheme,
};
